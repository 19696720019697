import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { useStepsContext } from "./context";
import { Button, ButtonGroup, Card, Col, Container, Row } from "reactstrap";
import NoRecordFound from "../Common/NoRecordFound";
import { parseJSON } from "../../Common/Appcommon";
import GobackButton from "../../Common/GoBack";

const SubscriptionPlans = () => {
    const { 
        plansList,
        noofMonth, 
        setNoofMonth,
        stripePromise,
        currentplan,
        setCurrentStep,
        setSelectedPlan,
        currentStep
    } = useStepsContext();

    const getPlanButtons = () => {
        const plansCycle = [
            {
                label : "Monthly",
                value : 1,
                isHide : false,
            },
            {
                label : "Quarterly",
                value : 3,
                isHide : true,
            },
            {
                label : "Half Yearly",
                value : 6,
                isHide : true,
            },
            {
                label : "Annually",
                value : 12,
                isHide : false,
            }
        ];
        return (
            <>
                <ButtonGroup className="mb-2">
                    {
                        plansCycle.map(({label, value, isHide}, index) => {
                            let key = "btn_no_" + index;
                            return (
                                <Button 
                                    key={key}
                                    className={`btn-outline-2x btn-square ${isHide ? "hide" : ""}`} 
                                    outline 
                                    color="light" 
                                    name={value}
                                    onClick={() => onRadioBtnClick(value)} 
                                    active={noofMonth === value}
                                >
                                    {label}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>
            </>
        );
    }

    const onRadioBtnClick = (selectedplan) => {
        setNoofMonth(selectedplan)
    }

    const handleBuyPlan = (plan) => {
        setCurrentStep(2);
        setSelectedPlan(plan)
    }

    const handleGoBack = () => {
        if(currentStep === 1){
            setCurrentStep(6)
        }
    }

    return(
        <Fragment>
            <Container fluid>
                <div className='account-pricing-container'>
                    {
                        currentStep === 1 &&
                        <div className={"m-3 "} style={{gap: "8px", display: "flex"}}>
                            <a className='cursor-pointer mr-3' onClick={e => handleGoBack()}>
                                <span className='d-flex align-center'>
                                    <i class="fi fi-br-arrow-left pr-1"></i> <span>Go Back</span>
                                </span>
                            </a>
                        </div>
                    }
                    {
                        plansList && plansList.length > 0 ? (
                            <div className='pricing-body m-3'>
                                <div className='plans-btn'>
                                    {getPlanButtons()}
                                </div>
                                <div className='plans-list m-3'>
                                    { 
                                        <Row lg="12">
                                            {
                                                plansList.map((plan, index) => {
                                                    let {plan_id, plan_name, plan_cost, plan_description, annual_discount, is_popular, other_features} = plan;
                                                    if (noofMonth === 12) {
                                                        plan_cost =  plan_cost - (plan_cost * (annual_discount / 100));
                                                    }
                                                    if (plan_cost) {
                                                        plan_cost = Number(plan_cost).toFixed(0); // Converts to a number and rounds to integer
                                                    }
                                                    if(other_features !== ""){
                                                        other_features = parseJSON(other_features);               
                                                    }
            
                                                    let isCurrentPlan = (currentplan.subscription_status === "Active" && plan_id === currentplan.plan_id) ?? false;
                                                    return (
                                                        <Col lg="4">
                                                            <Card className={`main-card mb-3 plan-card ${is_popular === "1" ? "selected-plan" : (isCurrentPlan ? "current-plan" : "")}`}>
                                                                <div className='plan-details'>
                                                                    <div>
                                                                        <div className='plan-title col-md-12'>
                                                                            <div className='col-md-6'>
                                                                                <span className='plan-name font-lbyl'>
                                                                                    {plan_name}
                                                                                </span>
                                                                            </div>
                                                                            <div className='col-md-6 plan-popular'>
                                                                                {
                                                                                    is_popular === "1" && 
                                                                                    <span className='recommended-tag'>
                                                                                        Most Popular
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className='plan-title-desc font-lbyl'>
                                                                            {plan_description}
                                                                        </div>
                                                                    </div>
                                                                    <div className='plan-price font-lbyl'>
                                                                        ${plan_cost}/m
                                                                    </div>
                                                                    <div className='plan-features'>
                                                                        {
                                                                            other_features && other_features.map((val, index) => {
                                                                            let iconKey = Object.keys(val).find(key => key.startsWith('cus_icon_'));
                                                                            let descKey = Object.keys(val).find(key => key.startsWith('cus_desc_'));
                                                                            let icon = val[iconKey];
                                                                            let desc = val[descKey];
                                                                                return (
                                                                                    <>
                                                                                        {
                                                                                            desc !== "" && <div className='plan-feature-description' key={index}>
                                                                                                <span className='plan-feature-text font-lbyl'>
                                                                                                    {icon}
                                                                                                </span>
                                                                                                <span className='plan-feature-text font-lbyl'>
                                                                                                    {desc}
                                                                                                </span>
                                                                                            </div>
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    {
                                                                        <div className='plan-footer'>
                                                                            <Button className='w-100 theme-bg-g' onClick={e => {e.stopPropagation(); handleBuyPlan(plan)}} disabled={!stripePromise || isCurrentPlan}>
                                                                                {isCurrentPlan ? "Current Plan" : "Subscribe " + plan_name}
                                                                            </Button>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    }
                                </div>
                            </div>
                        ) : ( 
                            <div>
                                <NoRecordFound isOnlyContent = {true}/>
                            </div>
                        )
                    }
                </div>
            </Container>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPlans);