import React, { useState, Fragment, useEffect } from 'react';
import Select from "react-select";
import { Button } from "reactstrap";
import Loading from '../../Common/Loading';
import { populateFormDetails, showToast } from '../../Common/Appcommon';

const ImportFieldMapper = (props) => {
    let { parsedData, onBackAction, setCurrentStep, setImportParam ,setError} = props;
    let contactform = "table_74";
    const [showLoading, setShowLoading] = useState(false);
    let [fileColumns, setFileColumns] = useState([]);
    let [firstRowData, setFirstRowData] = useState([]);
    let [formD, setFormDetail] = useState([]);
    let [fieldOptions, setFieldOptions] = useState([]);
    let [selectedMapping, setSelectedMapping] = useState([]);
    let [dateFormat, setDateFormatMapping] = useState([]);
    let [newEntry, setNewEntryMapping] = useState([]);
    const inString = process.env.REACT_APP_MAX_IMPORT_SIZE_FORMAT;

    useEffect(() => {
        (async () => {
            let formDetail = await populateFormDetails(contactform);
            setFormDetail(formDetail);
            await populateFieldOptions(formDetail);
        })();
    }, []);

    const populateFieldOptions = async (formDetails) => {
        let fieldDetails = formDetails[1];
        let options = [];
        for (let fi = 0; fi < fieldDetails.length; fi++) {
            let fd = fieldDetails[fi];
            let fieldName = fd[2];
            let fieldLabel = fd[3];
            let fieldType = fd[4];
            let isConfigHide = parseInt(fd[10]);
            let isMandatory = parseInt(fd[16]);
            let fieldOrder = fd[22];
            if (!isConfigHide) {
                if(fieldType !== "subtable"){
                    let attributes = { type: fieldType, isMandatory: isMandatory, order: fieldOrder };
                    let fieldoption = { value: fieldName, label: fieldLabel, attr: attributes };
                    options.push(fieldoption);
                }
            }
        }
        setFieldOptions(options);
    }

    useEffect(() => {
        (async () => {
            if (parsedData.length > 0 && parsedData[0][1] !== '' && parsedData[0][1] !== undefined && parsedData[0][0] !== '' && parsedData[0][0] !== undefined) {
                let columnData = parsedData[0][0].filter(item => item.trim() !== '');
                setFileColumns(columnData);
                setFirstRowData(parsedData[0][1]);
                await preparePreLoadedFieldMappings(columnData);
            }else{
                setCurrentStep(1);
                setError('Empty CSV files are not allowed for import.');
            }
        })();
    }, [parsedData]);

    const preparePreLoadedFieldMappings = async (columnData) => {
        let preFieldMapping = [];
        let preDateMapping = [];
        let preNewEntry = [];
        for (let ci = 0; ci < columnData.length; ci++) {
            preFieldMapping.push(-1);
            preDateMapping.push("");
            preNewEntry.push("");
        }
        setSelectedMapping(preFieldMapping);
        setDateFormatMapping(preDateMapping);
        setNewEntryMapping(preNewEntry);
    }

    const onHandleDropdownChange = (selectedField, index) => {
        let { name, label, attr } = selectedField;
        let { isMandatory, order, type } = attr;
        selectedMapping[index] = order;
        setSelectedMapping(selectedMapping);
        console.log(selectedMapping)
        if (type === "date") {
            dateFormat[index] = "DD/MM/YYYY";
            setDateFormatMapping(dateFormat);
        } else if (type === "ComboBox") {
            newEntry[index] = "true";
            setNewEntryMapping(newEntry);
        }
    }

    const onImportAction = async () => {
        let isAnyFieldSelected = false;
        for(let fm = 0; fm < selectedMapping.length; fm++){
            let fieldorder = parseInt(selectedMapping[fm]);
            if(fieldorder > -1){
                isAnyFieldSelected = true;
                break;
            }
        }
        if(isAnyFieldSelected){
            setShowLoading(true);
            initiateProcessImport();
            setCurrentStep(3);
        } else {
            showToast("Atleast one field should be mapped", "top-right", "warning");
        }
    }

    const initiateProcessImport = () => {
        let formInfo = formD[0];
        let params = {
            "ENTITY": "IMPORT",
            "OPERATION" : "IMPORT",
            "FORMNAME": contactform,
            "MODULENAME": formInfo[0],
            "command": "process",
            "fieldmappings": selectedMapping,
            "dateformat": dateFormat,
            "createnewentry": newEntry,
            "HasHeader": true,
            "mergetype": 2,
            "dupmappings":[]
        }
        setImportParam(params);
    }

    return (
        <Fragment>
            <div>
                <div className='meta-fields mb-3'>
                        <div className='w-100 '>
                            <div className='table-header w-100'>
                                <div className='d-flex bb-1'>
                                    <div className='import-data-1'>
                                        Sheet Columns
                                    </div>
                                    <div className='import-data-2'>
                                        <div>Sample Data</div>
                                    </div>
                                    <div className='import-data-3'>
                                        Field mapping
                                    </div>
                                </div>
                            </div>
                            <div className='table-body w-100'>
                                {
                                    fileColumns.map((column, index) => {
                                        let rowValue = firstRowData[index];
                                        return (
                                            <div className='d-flex bb-1'>
                                                <div className='import-data-1'>
                                                    {column}
                                                </div>
                                                <div className='import-data-2'>
                                                    <div className='data-middle'>{rowValue}</div>
                                                </div>
                                                <div className='import-data-3'>
                                                    <Select
                                                        onChange={(e) => {
                                                            onHandleDropdownChange(e, index);
                                                        }} options={fieldOptions}
                                                        className={`basic-multi-select`}
                                                        classNamePrefix="wa-select"
                                                        menuPlacement={`auto`}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between p-3 posison-relative">
                    <Button onClick={(e) => onBackAction(1)} size="lg" className='theme-bg-g'>Back</Button>
                    <Loading showLoader={showLoading} mainContent={
                        <Fragment>
                            <Button onClick={(e) => onImportAction(e)} size="lg" className='theme-bg-g'>Import</Button>
                        </Fragment>
                    } />
                </div>
        </Fragment>
    )
}

export default ImportFieldMapper;