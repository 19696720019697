export const LanguageOptions = [
    {
        label : "English",
        value : "en"
    },
    {
        label : "Spanish",
        value : "es"
    },
    {
        label : "French",
        value : "fr"
    },
    {
        label : "German",
        value : "de"
    },
    {
        label : "Japanese",
        value : "ja"
    },
    {
        label : "Chinese",
        value : "zh-CN"
    },
    {
        label : "Italian",
        value : "it"
    },
    {
        label : "Portuguese",
        value : "pt"
    },
    {
        label : "Russian",
        value : "ru"
    },
];