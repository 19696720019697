import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Common from '../Common';
import Select from "react-select";
import back_icon from "../Conversations/assets/images/back_icon.svg";
import { getReferenceList, getSessionValue,showToast, confirmBox } from '../../Common/Appcommon';
import requestApi from "../../services/requestApi.service";
import {
    Row,
    Col,
    Container,
    Button,
    Input
} from "reactstrap";
import { deleteMobileDetail, updateMobileDetail } from '../Agents/ProviderDetails';


const PhoneNumberContent = (props) => {
    const { handleSubmit: handleActionFormSubmit, control, formState: { errors }, setError,trigger } = useForm();
    const saveBtnRef = useRef(null);
    let { rowData, backToPN, deletePN, pnLabelMapper, pnFormDetails } = props;
    let [pnFormData, setPnFormData] = useState(rowData);
    let [defaultDpDown, setDefaultDpDown] = useState(null);
    let [agentDpDown, setAgentDpDown] = useState([]);
    let [agentIds,setAgentIds] = useState([])
    let [selectedAgent,setSelectedAgent] = useState("");
    let [showLoader , setShowLoader]  = useState(true);
    useEffect(() => {
        if (rowData && !pnFormData) {
            setPnFormData(rowData);
        }
        (async () => {
            setShowLoader(true);
            await fetchFormDetails();
            await fetchAgencyDetails();
            setShowLoader(false);
        })();
    }, [rowData]);

    const fetchAgencyDetails = async () => {
        let fieldName = pnLabelMapper['table_250_0_table_250_id'];
        let defaultAgent = pnFormData[fieldName + "_id"];
        let fDetail = pnFormDetails[1];

        let refDetail = fDetail.filter(record => record[2] === 'table_250_0_table_250_id')[0];
        if(refDetail && refDetail[35] !== undefined){
            let res = await getReferenceList(refDetail[35], defaultAgent);
            if (res.issuccess) {
                let optionList = res.data;
                setAgentDpDown(optionList[0]);
                let { value, label } = optionList[1];
                if (value && label) {
                    setDefaultDpDown(optionList[1]);
                    setSelectedAgent(agentIds[value]);
                }
            }
        }
    }

    const saveMobileDetail = async () => {
        if (!(await trigger("Name")) || !(await trigger("agent_id"))) {
            return;
        }
        setShowLoader(true);
        let providerName = getSessionValue("agent_provider_name");
        let param =  {
            id:pnFormData[pnLabelMapper['id']],
            name:pnFormData[pnLabelMapper['Name']],
            assistantid:selectedAgent,
        }
        let res = await updateMobileDetail(providerName,param);
        if (res.issuccess) {
            const reqobj = {
                ENTITY: "AIPROCESSOR",
                OPERATION: "SAVE_PN_DETAILS",
                provider: providerName,
                FORMDATA: res.data,
            };
            const response = await requestApi.processServerRequest(reqobj);
            const message = response.issuccess 
                ? "Mobile Number Updated Successfully" 
                : "Unable to Update";
            const toastType = response.issuccess ? "success" : "error";
            showToast(message, 'top-right', toastType);
        }
        setShowLoader(false);
        backToPN();        
    }

    const removeMobileDetail  = async () =>{
        let confirm = await confirmBox("Are you sure you want to delete?", "Press Delete to confirm",'warning','Delete','Cancel');
        if (!confirm) {
            return;
        }
        setShowLoader(true);
        let id = pnFormData[pnLabelMapper['id']];
        let providerName = getSessionValue("agent_provider_name");
        let param = {
            id:id,
        }
        let res =  await deleteMobileDetail(providerName,param);
        if (res.issuccess) {
            let reqobj = {
                ENTITY: "AIPROCESSOR",
                OPERATION: "DELETE_PN_DETAILS",
                provider: providerName,
                FORMDATA: res.data,
            };
            const response = await requestApi.processServerRequest(reqobj);
            const message = response.issuccess 
                ? "Mobile Number Deleted Successfully" 
                : "Unable to Delete";
            const toastType = response.issuccess ? "success" : "error";
            showToast(message, 'top-right', toastType);
        } else {
            showToast(res.errormsg, 'top-right', "error");
        }
        setShowLoader(false);
        backToPN();        
    }

    const onInputChange = (e) => {
        let { name, value } = e.target;

        setPnFormData((prevData) => ({
            ...prevData,
            [pnLabelMapper[name]]: value
        }));
    };

    const fetchFormDetails = async () => {
        let reqobj = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_AGENT_DETAILS",
        };
        let response = await requestApi.processServerRequest(reqobj);
        const keysMapping = {
            formid: 'table_250_id',
            agentis: 'Agent_ID',
        };
        const result = {}
        for (const row of response.data) {
            result[row[keysMapping.formid]] = row[keysMapping.agentis];
        }
        setAgentIds(result);
    };

    // const onSaveBtnClick = () => {
    //     saveBtnRef.current.click();
    // }

    const handleChange = async (option) => {
        let id = option.value;
        setSelectedAgent(agentIds[id]);
        setDefaultDpDown(option);
    }
    const mainContent = () => {
        return (
            <Container>
                <form id="phone-number-content-form" name={'phonenumber'}>
                    <div className='agency-details with-border'>
                        <div className='card-fields d-grid'>
                            <span className="heading" >Icon</span>
                            <span className='cloud-number-logo'>
                                <i class="fi fi-rr-mobile-button"></i>
                            </span>
                        </div>
                        <div className='field-details'>
                            <Row>
                                <Col md={6} className='mt-2'>
                                    <label className={'form-label'} htmlFor="Name">Name</label>
                                    <Controller
                                        name="Name"
                                        control={control}
                                        disabled={false}
                                        rules={{ required: (pnFormData[pnLabelMapper['Name']] === undefined || pnFormData[pnLabelMapper['Name']] === "") ? "Please provide Name" : "" }}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                type="text"
                                                id="Name"
                                                name="Name"
                                                value={pnFormData[pnLabelMapper['Name']] || ''}
                                                onChange={(e) => {
                                                    onInputChange(e);
                                                    field.onChange(e);
                                                }}
                                                className={`form-control ${errors.Name ? 'is-invalid' : ''}`}
                                            />
                                        )}
                                    />
                                    {
                                        errors.Name && <em className='invalid-feedback'>{errors.Name.message}</em>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className='mt-2'>
                                    <label className={'form-label'} htmlFor="Phone_Number">Phone number</label>
                                    <Controller
                                        name="Phone_Number"
                                        control={control}
                                        disabled={true}
                                        rules={{ required: "This field is required" }}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                type="text"
                                                id="Phone_Number"
                                                name="Phone_Number"
                                                value={pnFormData[pnLabelMapper['Phone_Number']] || ''}
                                                onChange={(e) => {
                                                    onInputChange(e);
                                                    field.onChange(e);
                                                }}
                                                className={`form-control ${errors.Phone_Number ? 'is-invalid' : ''}`}
                                            />
                                        )}
                                    />
                                    {
                                        errors.Phone_Number && <em className='invalid-feedback'>{errors.Phone_Number.message}</em>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='agency-details  with-border'>
                        <div className='card-fields d-grid'>
                            <span className="heading" >AI Agent</span>
                            <span className='header-desc'>Assign agent to phone number</span>

                        </div>
                        <div className='field-details'>
                            <Row>
                                <Col md={6} className='mt-2'>
                                    <label className={'form-label'} htmlFor="brand_name">Select agent</label>
                                    <Controller
                                        name="agent_id"
                                        control={control}
                                        rules={{ required: (defaultDpDown === undefined || defaultDpDown === "" || defaultDpDown == null) ? "This field is required" : "" }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                isClearable={false}
                                                onChange={(eve) => {
                                                    handleChange(eve)
                                                }}
                                                name="agent_id"
                                                id="agent_id"
                                                classNamePrefix="sub-select wa-select"
                                                placeholder={"Choose a ai name"}
                                                options={agentDpDown}
                                                value={defaultDpDown}
                                                menuPlacement="auto"
                                                className={`basic-select ${errors.agent_id ? 'is-invalid' : ''}`}
                                            />
                                         )}
                                    />
                                    {
                                        errors.agent_id && <em className='invalid-feedback'>{errors.agent_id.message}</em>
                                    } 
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='agency-details  with-border hide'>
                        <div className='card-fields d-grid'>
                            <span className="heading" >Telephony provider</span>
                            <span className='header-desc'>{pnFormData[pnLabelMapper['Provider']]}</span>
                        </div>
                    </div>
                    <div className='agency-details'>
                        <div className='card-fields d-grid'>
                            <span className='heading'>Delete</span>
                            <span className="header-desc" >Delete your phone number from your account</span>
                        </div>
                        <div className='field-details delete-number'>
                            <Button className='btn-hover-shine theme-bg-g custom-btn-1' onClick={ async (e) => { await removeMobileDetail() } }> 
                                Delete phone number 
                            </Button>
                        </div>
                    </div>
                    <div className='hide'>
                        <button ref={saveBtnRef} onClick={handleActionFormSubmit((data, event) => saveMobileDetail(event))} className="btn btn-outline-primary">Save & Activate</button>
                    </div>
                </form>
            </Container>
        );
    };
    
    return (
        <Fragment>
            <Common 
                title = {
                    "Agency Details"
                }
                titleContent={
                    <div className='container-heading-title'>
                        <span >
                            <img className='mat-icon' src={back_icon} onClick={(e) => { backToPN(e) }} />
                        </span>
                        {<span>{pnFormData[pnLabelMapper['Name']]} ( {pnFormData[pnLabelMapper['Phone_Number']]} )</span>}
                    </div>
                }
                titleBody={
                    <>
                        <Button onClick={(e) => { saveMobileDetail(e) }} className="compact-padding theme-bg-g" color="light">Save</Button>
                    </>
                }
                mainLoader = {
                    showLoader
                }
                mainContent={
                    mainContent()
                } 
            />


        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumberContent);
