import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { Container, Button } from "reactstrap";
import invoice from "./assets/images/invoice.png";
import { useStepsContext } from "./context";

const SubscriptionConfirm = () => {
    const { isUsage, setCurrentStep } = useStepsContext();

    const handlePaymentConfirmPage = () => {
        if(isUsage){
            setCurrentStep(6);
        }else{
            window.location.hash = "#/usage";
        }
    }

    return (
        <Fragment>
            <Container fluid>
                <div className="no-records-container">
                    <div className="d-grid align-center">
                        {/* <i class="fi fi-ss-badge-check success-tick-icon"></i> */}
                        <img src={invoice} className="w-25" alt="Stripe Logo"></img>
                        <div className="mt-3 font-color">
                            <span className="h3">Updated Successfully</span>
                            <br/>
                            <span>Your subscription has been updated.</span>
                        </div>
                        <Button className="theme-bg-g mt-3 w-25" style={{"margin": "auto"}} onClick={e => handlePaymentConfirmPage()}>
                            Go to Home
                        </Button>
                    </div>
                </div>
            </Container>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionConfirm);