import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Controller } from "react-hook-form";
import { Col, Input, Row } from "reactstrap";
import Select from "react-select";

const VariablesContainer = forwardRef((props, ref) => {
    const { control, list, formFields, formdata } = props;
    const [formData, setFormData] = useState([]);

    useEffect(() => {
        setFormData(formdata);
    }, [])

    const validateController = (type, variable) => {
        let response = false;
        if(type === "field"){
            if(!formData[variable]){
                response = "This field is required";
            }
        }else if(type === "fallback"){
            if( formData &&
                formData[variable] &&
                formData[variable]["field"] &&                
                formData[variable]["field"]["value"] === "fallback" && (
                    !formData[variable]["fallback"] ||
                    formData[variable]["fallback"] === ""
                )
            ){
                response = "This field is required";
            }
        }
        return response;
    }
    
    useImperativeHandle(ref, () => ({
        returnVariablesValues,
    }));

    const returnVariablesValues = () => {
        return formData;
    }

    const handleInputChange = (e, type) => {
        const { name, value } = e.target; // Extract name (key) and value
        setFormData((prevFormData) => ({
            ...prevFormData,   // Keep the existing data
            [name]: {
                ...prevFormData[name], // Spread existing nested object (if it exists)
                [type] : value
            }
        }));
    };
    
    const onSelectChange = (option, name, type) => {
        setFormData((prevFormData) => ({
            ...prevFormData, // Spread existing formData
            [name]: {
                ...prevFormData[name], // Spread existing nested object (if it exists)
                [type]: option, // Update or add the specific type key
            },
        }));
    };

    return (
        <div className="mt-1">
            {
                list && list.map((value, index) => (
                    <Row lg={12} className="mt-3">
                        <Col lg={4}>
                            <label htmlFor={"variable"}>Variable</label>
                            <Controller 
                                name={"variable"}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <>
                                        <Input 
                                            {...field}
                                            type={"text"} 
                                            value={`{{${value}}}`} 
                                            disabled={true}
                                            className={`form-control ${error ? 'is-invalid' : ''}`}
                                            title={value}
                                        />
                                        {error && (
                                            <em className="error invalid-feedback d-flex mb-1">{error.message}</em>
                                        )}
                                    </>
                                )}
                            />
                        </Col>
                        <Col lg={4}>
                            <label htmlFor={"field"}>Field</label>
                            <Controller 
                                name={"field"}
                                control={control}
                                rules={{
                                    required: validateController("field", value),
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <>
                                        <Select 
                                            {...field}
                                            type={"text"} 
                                            name={value}
                                            value={formData && formData[value] && formData[value]["field"] ? formData[value]["field"] : false}
                                            options={formFields}
                                            onChange={e => onSelectChange(e, value, "field")}
                                            classNamePrefix="wa-select"
                                            className="basic-select"
                                        />
                                        {error && (
                                            <em className="error invalid-feedback d-flex mb-1">{error.message}</em>
                                        )}
                                    </>
                                )}
                            />
                        </Col>
                        <Col lg={4}>
                            <label htmlFor={"fallback"}>Fallback</label>
                            <Controller 
                                name={"fallback"}
                                control={control}
                                rules={{
                                    required: validateController("fallback", value),
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <>
                                        <Input 
                                            {...field}
                                            type={"text"} 
                                            name={value}
                                            value={
                                                formData &&
                                                formData[value] && 
                                                formData[value]["fallback"] &&
                                                formData[value]["field"] && 
                                                formData[value]["field"]["value"] === "fallback" ? (
                                                    formData[value]["fallback"]
                                                ) : ("")
                                            }
                                            className="form-control"
                                            onChange={e => handleInputChange(e, "fallback")}
                                            placeholder="Fallback value"
                                            disabled={
                                                formData &&
                                                formData[value] && 
                                                formData[value]["field"] && 
                                                formData[value]["field"]["value"] !== "fallback"
                                            }
                                            autoComplete="off"
                                        />
                                        {error && (
                                            <em className="error invalid-feedback d-flex mb-1">{error.message}</em>
                                        )}
                                    </>
                                )}
                            />
                        </Col>
                    </Row>
                ))
            }
        </div>
    )
})

export default VariablesContainer;