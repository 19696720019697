import { Route, Redirect } from "react-router-dom";
import React, { Fragment } from "react";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Login from "../../Components/Login";
import Agents from "../../Components/Agents";
import Conversations from "../../Components/Conversations";
import Goal from "../../Components/Analysis/Goal";
import ABTest from "../../Components/CallAnalysis/ABTest";
import LostCall from "../../Components/Analysis/LostCall";
import PhoneNumber from "../../Components/Settings/PhoneNumber";
import Users from "../../Components/Settings/Users";
import General from "../../Components/Agency/General";
import Subaccounts from "../../Components/Agency/Subaccounts";
import CustomDomain from "../../Components/Agency/CustomDomain";
import Profile from "../../Components/Profile";
import SiteLogin from "../../Site/Login/index";
import ResetPassword from "../../Site/ForgotPassword/resetpassword";
import ForgotPassword from "../../Site/ForgotPassword/index";
import PricingList from "../../Components/Agency/PricingList";
import Payment from "../../Components/Agency/Payment";
import InviteForm from "../../Site/Signup";
import Contacts from "../../Components/Contacts/Contacts";
import ContactsLists from "../../Components/Contacts/List";
import Tags from "../../Components/Contacts/Tags";
import CallLog from "../../Components/CallAnalysis/Calllog";
import ActivateSignup from "../../Site/Signup/ActivateSignup";
import MainDashboard from "../../Components/MainDashboard";
import OutboundService from "../../Components/Outbound";
import OutboundOperations from "../../Components/Outbound/Operations";
import CallVolume from "../../Components/CallAnalysis/CallVolume";
import CallDuration from "../../Components/CallAnalysis/CallDuration";
import CallOutcome from "../../Components/CallAnalysis/CallOutcome";
import CallHeatmap from "../../Components/CallAnalysis/CallHeatmap";
import Webhooks from "../../Components/Webhooks";
import ImportExport from "../../Components/Contacts/ImportExport";
import CustomFields from "../../Components/Contacts/CustomFields";
import UsageMain from "../../Components/Usage";
// import GoogleCalendar from "../../Components/Integrations/GoogleCalendar";
import Integrations from "../../Components/Integrations";
// import ProfitReport from "../../Components/ProfitReport";


const AppMain = () => {

    return (
        <Fragment>
            <Route path="/login_check" component={Login}/>
            <Route path="/dashboard" component={MainDashboard}/>
            <Route path="/agents" component={Agents}/>
            <Route path="/calls" component={Conversations}/>
            <Route path="/goals" component={Goal}/>
            <Route path="/abtesting" component={ABTest}/>
            <Route path="/lostcalls" component={LostCall}/>
            <Route path="/numbers" component={PhoneNumber}/>
            <Route path="/users" component={Users}/>
            <Route path="/general" component={General}/>
            <Route path="/subaccounts" component={Subaccounts}/>
            <Route path="/custom_domain" component={CustomDomain}/>
            <Route path="/pricing" component={PricingList}/>
            <Route path="/payment" component={Payment}/>
            <Route path="/profile" component={Profile}/>
            <Route path="/contacts" component={Contacts}/>
            <Route path="/contact/import-export" component={ImportExport}/>
            <Route path="/lists" component={ContactsLists}/>
            <Route path="/tags" component={Tags}/>
            <Route path="/logs/:fil_cond?" component={CallLog} />
            <Route path="/campaigns" render={(props) => <OutboundService {...props} type="campaign" />} />
            <Route path="/automations" render={(props) => <OutboundService {...props} type="automation" />} />
            <Route 
                path={["/campaign/new", "/campaign/edit/:formId"]} 
                render={(props) => <OutboundOperations {...props} type="campaign" />} 
            />
            <Route 
                path={["/automation/new", "/automation/edit/:formId"]} 
                render={(props) => <OutboundOperations {...props} type="automation" />} 
            />
            <Route 
                path={["/subscription", "/usage"]} 
                render={() => <UsageMain/>} 
            />
            <Route path="/callsvolume" component={CallVolume}/>
            <Route path="/duration" component={CallDuration}/>
            <Route path="/wonlost" component={CallOutcome}/>
            <Route path="/heatmap" component={CallHeatmap}/>
            <Route path="/custom-fields" component={CustomFields}/>
            {/* <Route path="/profit_report" component={ProfitReport}/> */}
            <Route exact path="/" render={() => (
                <Redirect to="/login_check"/>
            )}/> 
            <Route path="/login" component={SiteLogin}/>
            <Route path="/forgotpassword" component={ForgotPassword} />
            <Route path="/resetpassword" component={ResetPassword} />
            <Route path="/activate/:activate_code" component={ActivateSignup} />
            <Route path="/invite/:invite_code" component={InviteForm} />
            <Route path="/webhooks" component={Webhooks} />
            <Route path={["/integrations", "/integrations/:type"]} component={Integrations} />
            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;
