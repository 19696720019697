import React, { Fragment, useCallback } from "react";
import {
    Row,
    Col,
    Container
  } from "reactstrap";
import CountUp from "react-countup";
import { formatCurrency, tostring,getSessionValue } from "../../Common/Appcommon";
import {encode as base64_encode } from 'base-64';

export const DashboardSummaryBox = (props) => {
    const { summary,dateFilter } = props;
    let isAllowClick = getSessionValue('isAllowEditAgent') === true || getSessionValue('isAllowEditAgent') === "true" ? true : false;
    const getFormattingFn = useCallback(
        (prefix) => (prefix ? formatCurrency : tostring),
        [] 
    );

    const openCalllogs = (field) =>{
        if(!isAllowClick){
            return;
        }
        let filVal = {
            fieldname : field.fieldname,
            date : dateFilter,
            header : field.header,
        }
        let param = {
            filterType : "sub_dashboard",
            filterValue : filVal
        }
        let paramList = JSON.stringify(param);
        paramList= base64_encode(paramList);
        window.location = `#/logs/${paramList}`;
    }
    return (
        <Fragment>
            <Container>
                <div className="summary-container">
                    <Row>
                        {summary &&
                            summary.map((val, index) => {
                                let header = val.header;
                                let body = val.body;
                                let footerheader = val.footerheader;
                                let footerbody = val.footerbody;
                                let prefix = val.prefix;
                                let suffix = val.suffix;
                                let isFloat = body % 1 !== 0;
                                let formattingFn = getFormattingFn(prefix); 

                                return (
                                    <Col md="6" lg="3" key={index} className={`${isAllowClick ? 'cursor-pointer' : ''}`}  onClick={(e) => {openCalllogs(val)}}>
                                        <div className="card mb-3 widget-chart text-start">
                                            <div className="widget-chart-content">
                                                <div className="widget-subheading">{header}</div>
                                                <div className="widget-numbers">
                                                    <CountUp
                                                        start={0}
                                                        end={body}
                                                        separator=","
                                                        decimals={isFloat ? 2 : 0}
                                                        decimal="."
                                                        prefix={prefix}
                                                        suffix={suffix}
                                                        useEasing={false}
                                                        duration={3}
                                                        className="vi-success-text"
                                                        formattingFn={formattingFn} 
                                                    />
                                                </div>
                                                <div className="widget-description">
                                                    <span className="ps-1">
                                                        <span className="theme-c-g">
                                                            {footerbody}
                                                        </span>
                                                        <span className="dashboard-footerheader">
                                                            {footerheader}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })}
                    </Row>
                </div>
            </Container>
        </Fragment>
    );
};