import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardBody, Container, Button } from "reactstrap";
import DataTable from 'react-data-table-component';
import Select from "react-select";

const CustomListTable = (props) => {
    
    let { data, columns, isDashboard, selectableRows, onSelectable, clearRows,handleChange,isAllowLoadmore,totalRows,tblCurPage,recordsPerPage,rowOptions,offSet,isMobile } = props
    const [currentPage, setCurrentPage] = useState(tblCurPage);
    const [perPage, setPerPage] = useState(recordsPerPage);
    
    const pageCustomStyles = {
        control: (base) => ({
            ...base,
            borderRadius: '5px',
            border: '1px solid #ccc',
            display: 'flex',
            boxShadow: 'none',
            ':hover': {
                borderColor: '#888',
            },
        }),
        option: (base, { isFocused }) => ({
            ...base,
            backgroundColor: isFocused ? '#30c384' : 'white',
            color: '#333',
        }),
        dropdownIndicator: (base) => ({
            ...base,
            color: '#888',
            ':hover': {
                color: '#555',
            },
        }),
        menu: (base) => ({
            ...base,
            position: 'absolute',
            top: 'auto', // Reset default behavior
            bottom: '100%', // Position above the select box
            marginBottom: '8px', // Add space between select box and dropdown
            zIndex: 9999, // Ensure it's above other elements
        }),
    };

    let headStyle = {
        fontSize: '12px',
        fontWeight: '700',  // Custom font size for header cells
        fontWeight: 'bold',  // Optional: Make headers bold
        color: '#000000',
        alignItems: 'left',
    };
    let colStyle = {
        fontSize: '14px',  // Custom font size for data cells
    };

    if(selectableRows){
        headStyle['&:first-of-type'] = {
            width: '20px', // Reduce the width of the first column
            minWidth: '20px !important',
        }
        colStyle['&:first-of-type'] = {
            width: '20px', // Reduce the width of the first column
            minWidth: '20px !important',
        }
    }

    const customStyles = {
        headCells: {
            style: headStyle
        },
        table: {
        style: {
            borderRadius: '10px',
            overflow: 'hidden'
        }
        },
        table: {
        style: {
            borderRadius: '10px',
            overflow: 'hidden'
        }
        },
        cells: {
        style: colStyle
        },
        rows: {
        style: {
            minHeight: '40px', // Ensure each row has a minimum height (optional)
        },
        },
        tableWrapper: {
        style: {
            //minHeight: '70vh', // Set a minimum height for the table
            //maxHeight: '70vh', // Set a minimum height for the table
        },
        },
        pagination: {
        style: {
            borderTop: '0',
            display: 'flex',
            justifyContent: 'center',  // Center the pagination component
            alignItems: 'center',
            paddingTop: '8px',
        },
        }
    };
    
    const handlePerPageChange = (event) => {
        setPerPage(event?.value);
        handleChange(0, 1, event?.value);
    };

    const pageChange = (page) => {
        const totalPages = Math.ceil(totalRows / perPage);
        if (page < 1 || page > totalPages) {
          console.warn("Invalid page number:", page);
          return;
        }
        let updatedOffset = (page - 1) * perPage;
        handleChange(updatedOffset, page, perPage);
        setCurrentPage(page);
      };
    
    
    const CustomPagination = () => {
        const totalPages = Math.ceil(totalRows / perPage);
        return (
            <div className={`align-items-center mt-3 ms-5 ${isMobile ? "" : "d-flex"}`}>
                <div>
                    <span className="cus-pag-spn">
                        Show:{" "}
                        <Select
                            options={rowOptions}
                            defaultValue={rowOptions.find((option) => option.value === perPage)}
                            styles={pageCustomStyles}
                            onChange={handlePerPageChange}
                        />
                        entries
                    </span>
                </div>
                <div className="ms-auto me-5">
                    <span>
                        {` Showing ${(currentPage - 1) * perPage + 1} to ${Math.min(
                            currentPage * perPage,
                            totalRows
                        )} of ${totalRows} entries`}
                    </span>
                </div>
                <div className="pagination cus-pagination me-5">
                    <Button
                        disabled={currentPage === 1}
                        onClick={() => pageChange(currentPage - 1)}
                        className="cus-table-btn"
                    >
                        <i className="fi fi-rs-angle-small-left"></i>
                    </Button>
                    {generatePaginationButtons()}
                    <Button
                        disabled={currentPage === totalPages}
                        onClick={() => pageChange(currentPage + 1)}
                        className="cus-table-btn"
                    >
                        <i className="fi fi-ss-angle-small-right"></i>
                    </Button>
                </div>
            </div>
        );
    }; 

    const generatePaginationButtons = () => {
        const totalPages = Math.ceil(totalRows / perPage);
        const buttons = [];
        const visibleButtons = 5; // Number of visible page buttons
        if (totalPages <= visibleButtons) {
            for (let i = 1; i <= totalPages; i++) {
                buttons.push(
                    <Button
                        key={i}
                        onClick={() => pageChange(i)}
                        className={`cus-table-btn ${currentPage === i ? "active" : ""}`}
                    >
                        {i}
                    </Button>
                );
            }
        } else {
            // Logic for dynamic pagination with ellipsis
            if (currentPage > 3) {
                buttons.push(
                    <Button key="first" className="cus-table-btn" onClick={() => pageChange(1)}>
                        1
                    </Button>
                );
                if (currentPage > 4) {
                    buttons.push(<span key="dots-left">...</span>);
                }
            }
            const startPage = Math.max(1, currentPage - 1);
            const endPage = Math.min(totalPages, currentPage + 1);
            for (let i = startPage; i <= endPage; i++) {
                buttons.push(
                    <Button
                        key={i}
                        onClick={() => pageChange(i)}
                        className={`cus-table-btn ${currentPage === i ? "active" : ""}`}
                    >
                        {i}
                    </Button>
                );
            }
            if (currentPage < totalPages - 2) {
                if (currentPage < totalPages - 3) {
                    buttons.push(<span key="dots-right">...</span>);
                }
                buttons.push(
                    <Button key="last" className="cus-table-btn" onClick={() => pageChange(totalPages)}>
                        {totalPages}
                    </Button>
                );
            }
        }
        return buttons;
    };

    if(isAllowLoadmore){

        return (
            <>
            <Fragment>
                <div className="p-3">
                    <Col md="12">
                    <Card className="main-card scrollable-table">
                        <CardBody>
                            <DataTable
                            data={data}
                            columns={columns}
                            fixedHeader
                            fixedHeaderScrollHeight="100vh"
                            className="list-data-table"
                            selectableRows={selectableRows}
                            clearSelectedRows={clearRows}
                            customStyles={customStyles}
                            />
                            <CustomPagination />
                        </CardBody>
                    </Card>
                    </Col>
                </div>
            </Fragment>
            </>
        )
    }
}

export default CustomListTable;
