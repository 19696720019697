import React, { Fragment } from 'react';
import { connect } from "react-redux";
const ListViewHeader = (props) => {
    let { content, formLabel } = props;
    return (
        <Fragment>
            <div className={`list-header__main`}>
                <div className='col-md-3 d-flex align-center pl-2 justify-start'>
                    <span className='header-title'>{formLabel}</span>
                </div>
                <div className='col-md-9 d-flex align-center pl-2 justify-end m-3'>
                {content}
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ListViewHeader);
