import React, { Fragment, useState } from "react";
import { Col, Card, CardBody, Container } from "reactstrap";
import DataTable from 'react-data-table-component';

const ListTable = (props) => {
  let { data, columns, isDashboard, selectableRows, onSelectable, clearRows } = props
  const [selectedRows, setSelectedRows] = useState([]);
  let headStyle = {
    fontSize: '12px',
    fontWeight: '700',  // Custom font size for header cells
    fontWeight: 'bold',  // Optional: Make headers bold
    color: '#034737',
    alignItems: 'left',
  };
  let colStyle = {
    fontSize: '14px',  // Custom font size for data cells
  };
  if(selectableRows){
    headStyle['&:first-of-type'] = {
      width: '20px', // Reduce the width of the first column
      minWidth: '20px !important',
      'accent-color': '#034737',
    }
    colStyle['&:first-of-type'] = {
      width: '20px', // Reduce the width of the first column
      minWidth: '20px !important',
      'accent-color': '#034737',
    }
  }
  const customStyles = {
    headCells: {
      style: headStyle
    },
    table: {
      style: {
        borderRadius: '10px',
        overflow: 'hidden'
      }
    },
    table: {
      style: {
        borderRadius: '10px',
        overflow: 'hidden'
      }
    },
    cells: {
      style: colStyle
    },
    rows: {
      style: {
        minHeight: '40px', // Ensure each row has a minimum height (optional)
      },
    },
    tableWrapper: {
      style: {
        //minHeight: '70vh', // Set a minimum height for the table
        //maxHeight: '70vh', // Set a minimum height for the table
      },
    },
    pagination: {
      style: {
        borderTop: '0',
        display: 'flex',
        justifyContent: 'center',  // Center the pagination component
        alignItems: 'center',
        paddingTop: '8px',
      },
    }
  };
  const paginationOptions = {
    noRowsPerPage: true, // Hides the "Rows per page" option
  };
  if (isDashboard) {

    if(!data){
      return (
        <Fragment>
          
        </Fragment>
      )
    }

    return (
      <Fragment>
        <div className="dashboard-table-container">
          <Col md="12">
            <Card className="main-card scrollable-table">
              <CardBody>
                <DataTable
                  data={data}
                  columns={columns}
                  pagination={data.length > 10}
                  fixedHeader
                  fixedHeaderScrollHeight="100vh"
                  className="list-data-table"
                  customStyles={customStyles}
                  paginationComponentOptions={paginationOptions}
                />
              </CardBody>
            </Card>
          </Col>
        </div>
      </Fragment>
    )
  }
  return (
    <>
      <Fragment>
          <div className="p-3">
            <Col md="12">
              <Card className="main-card scrollable-table">
                <CardBody>
                  <DataTable
                    data={data}
                    columns={columns}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight="100vh"
                    className="list-data-table"
                    customStyles={customStyles}
                    selectableRows={selectableRows}
                    clearSelectedRows={clearRows}
                    onSelectedRowsChange={(e) => {onSelectable(e)}}
                    //selectableRowSelected={row => selectedRows.includes(row)}
                  />
                </CardBody>
              </Card>
            </Col>
          </div>
      </Fragment>
    </>
  )
}

export default ListTable;
