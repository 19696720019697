import React, { useEffect, Fragment, useRef, useState } from "react";
import cx from "classnames";
import { Input } from "reactstrap";

const CratioMobileInput = (props) => {
    const [value, setValue] = useState(props.value);
    useEffect(() => {
        (async () => {})();
    });

    useEffect(() => {
        setValue(props.value);
      }, [props.value]);

    const onChange = async (e) => {
        if(e.target.validity.valid){
            let name = e.target.name;
            let value = e.target.value;
            if (props.onChange) {
                props.onChange(e, {name: name, value: value}, props.attributes);
            }
            setValue(value);
        }
    };
    return (
        <div className={cx(props.customClass)} {...props.attributes}>
            <Input
                className={`${props.inputClass}`}
                type={"text"} // Set to text to allow + and -
                value={value}
                placeholder={props.placeholder}
                autoComplete={"off"}
                onChange={(e) => {
                    const { value } = e.target;
                    // Allow only numbers, '+' and '-' values
                    if (/^[-+]?[0-9]*$/.test(value)) {
                    onChange(e); // Pass only valid values
                    }
                }}
                {...props.controls}
                maxLength={16}
            />
            {/* {props.externalNote && <span className="field-desc">Phone number with country code and + sign without spaces or dashes.</span>} */}
        </div>
    );
};

export default CratioMobileInput;
