import React, { Fragment, useState, useEffect } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import Common from '../Common';
import { getSessionValue, clearSessionValue, getLogoutURL, clearLegacyCookie, handleErrorResponse } from '../../Common/Appcommon';
import requestApi from '../../services/requestApi.service';
import cx from "classnames";
import { useTheme } from '../../Context/ThemeContext';
import { Label, Col, Input, InputGroup, Button, Row, FormText } from 'reactstrap';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { showToast, confirmBox } from '../../Common/Appcommon';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from "react-select";
import { LanguageOptions } from './Constants/Constant';
import { useLanguage } from '../../Context/LanguageContext';

const Profile = () => {
    const [isToggleTheme, setIsToggleTheme] = useState(false);
    const { theme, toggleTheme } = useTheme();
    const [isCopied, setTextCopied] = useState(false);
    const [apiKey, setapiKey] = useState("");
    const [lastUpdated, setlastUpdated] = useState("");
    const [showAPI, setshowAPI] = useState(false);
    const { language, setLanguage } = useLanguage();
    const [selectedLanguage, setSelectedLanguage] = useState({});

    useEffect(() => {
        if (theme === "dark-theme") {
            setIsToggleTheme(true);
        }
        if(language !== ""){
            LanguageOptions.map((option, index) => {
                let label = option.label;
                let value = option.value;
                if(value === language){
                    setSelectedLanguage(option);
                }
            })
        }
        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    useEffect(() => {
        (async () => {
            await getAPIKey();
        })();
    }, []);

    const getAPIKey = async (isNewApi = false) => {
        let appid = getSessionValue("appid");
        let username = getSessionValue("loginuser");
        let usertableid = getSessionValue("loginuserid");
        let params = {
            "ENTITY": "API_PROCESS",
            "OPERATION": "GENERATE_API_KEY",
            "isexist": isNewApi ? "true" : "false",
            "isfirsttime": isNewApi ? "true" : "false",
            "APPID": appid,
            "username": username,
            "usertableid": usertableid,
        }
        let response = await requestApi.processServerRequest(params);
        console.log(response)
        if (response.issuccess) {
            let data = response.data;
            if (isNewApi) {
                showToast("New Key Generated");
            }
            setapiKey(data[1] !== undefined && data[1] !== null && data[1] !== "" ? data[1] : "");
            setlastUpdated(data[2] !== undefined && data[2] !== null && data[2] !== "" ? data[2] : "");
        }
    }

    const generateNewKey = async () => {
        let generateNewKey = await confirmBox("API Key", "API key exists already.click yes to reset or click no to view exist key.", 'warning', 'Yes', 'No');
        if (generateNewKey) {
            setshowAPI(true);
            await getAPIKey(true);
        }
    }

    const copyUrl = () => {
        setTextCopied(true);
    }

    const logoutApp = async (e) => {
        //let activeSessionCount = 1;
        let params = {
            "ENTITY": "session_check",
        }
        let sessionResponse = await requestApi.processServerRequest(params);
        if (sessionResponse.issuccess) {
            //activeSessionCount = sessionResponse.data['activeSessions'];
            let params = {
                "ENTITY": "logout",
                'logoutfromall': false
            };
            clearLegacyCookie();
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                clearSessionValue();
                if (response.data !== "") {
                    window.location = response.data;
                } else {
                    window.location = getLogoutURL();
                }
            } else {
                handleErrorResponse(response.errormsg);
            }
        }
    }

    const updateThemeSelection = async (theme) => {
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "UPDATE_THEME",
            "THEME": theme,
        };
        let response = await requestApi.processServerRequest(params);
        if (response.issuccess) {

        }
    }

    const onLanguageChange = async (lang) => {
        let {value} = lang;
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "UPDATE_LANGUAGE",
            "LANGUAGE": value,
        };
        let response = await requestApi.processServerRequest(params);
        if (response.issuccess) {
            setLanguage(value);
            setSelectedLanguage(lang);
            //window.location.reload();
        }
    }

    const onThemeChange = () => {
        setIsToggleTheme(!isToggleTheme);
        let themeOption = "light-theme";
        if (!isToggleTheme) {
            themeOption = "dark-theme";
        }
        toggleTheme(themeOption);
        updateThemeSelection(themeOption);
    }

    let userName = getSessionValue("loginuser");
    let userEmail = getSessionValue("loginemail");
    return (
        <Fragment>
            <Common titleContent={
                <span>Profile</span>
            } mainContent={
                <div className='container overflow-auto h-100'>
                    <div className='agency-details'>
                        <div className='card-fields d-grid'>
                            <span class="heading">Name</span>
                            <span className='profile-desc'>{userName}</span>
                        </div>
                        <div className='card-fields d-grid'>
                            <span class="heading">Email</span>
                            <span className='profile-desc'>{userEmail}</span>
                        </div>
                        <div className='card-fields hide'>
                            <span class="heading">Business Name</span>
                            <span className='profile-desc'>{userName}</span>
                        </div>
                        <div className='card-fields d-grid'>
                            <span class="heading">Theme</span>
                            <div className="switch has-switch mb-2 me-2" data-on-label="ON"
                                data-off-label="OFF" onClick={onThemeChange}>
                                <div className={cx("switch-animate", {
                                    "switch-on": isToggleTheme,
                                    "switch-off": !isToggleTheme,
                                })}>
                                    <input type="checkbox" />
                                    <span className={`switch-left bg-dark ${isToggleTheme ? 'pe-3 ps-3 pt-1' : ''}`}>Dark</span>
                                    <label>&nbsp;</label>
                                    <span className="switch-right bg-dark ps-2 pt-1">Light</span>
                                </div>
                            </div>
                        </div>
                        
                        <div className='d-grid'>
                            <Row>
                                <span class="heading pb-2">API Key</span>
                                <Col md={6} className="pr-0">
                                    <div className="d-flex align-items-center">
                                        <InputGroup>
                                            <Input value={showAPI ? apiKey : `************${apiKey.slice(-4)}`} className={`google-ads-webhook-url`} disabled={true} />
                                            <div className="input-group-text theme-bg-g">
                                                <CopyToClipboard onCopy={copyUrl} text={apiKey}>
                                                    <FontAwesomeIcon icon={faCopy} />
                                                </CopyToClipboard>
                                            </div>
                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col md={4} className='ps-2'>
                                    <div className="d-flex align-items-center">
                                        <Button className={"theme-bg-g api-btn-height"} onClick={generateNewKey}>
                                            {'Re-generate API Key'}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            {isCopied && (
                                <FormText color="success" className="mb-2 ">Text has been copied.</FormText>
                            )}
                            <div className='hide'>
                                <Label className='pt-3'>Last Updated At</Label>
                                <div className='font-bold'>
                                    {lastUpdated}
                                </div>
                            </div>
                        </div>
                        <div className='card-fields d-grid'>
                            <div className='col-md-6'>
                            <span className='heading'>Language</span>
                            <Select
                                isClearable={false}
                                onChange={(eve) => {
                                    onLanguageChange(eve)
                                }}
                                placeholder={"Choose a Language"}
                                value={selectedLanguage}
                                options={LanguageOptions}
                                className='basic-multi-select me-4'
                                classNamePrefix="wa-select"
                                menuPlacement={"top"}
                            />
                            </div>
                        </div>
                        <div>
                            <button onClick={(e) => logoutApp(e)} className='btn btn-light theme-bg-g custom-display'><i class="fi fi-bs-sign-out-alt"></i><span className='pl-1'>Log out</span></button>
                        </div>
                    </div>
                </div>
            } />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
