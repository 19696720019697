import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { formatDateTime,convertUTCToLocalTime, getLoginUserInfo } from '../../Common/Appcommon';

const CallsOverview = ({ rowdata, lablename, nameLabelMapper }) => {
    const agentLabel = [nameLabelMapper['table_250_0_table_250_id'] || "", "AI Agent"];
    const agentNumLabel = [nameLabelMapper['Caller_No'] || "", "Phone Number"];
    const cusNameLabel = [nameLabelMapper['table_74_0_table_74_id'] || "", "Customer"];
    const customernumLabel = [nameLabelMapper['displayNumber'] || "", "Customer Number"];
    const costLabel = [nameLabelMapper['Call_Cost'] || "", "Cost"];
    const startTimeLabel = [nameLabelMapper['Start_DateTime'] || "", "Started At"];
    const endTimeLabel = [nameLabelMapper['End_DateTime'] || "", "Ended At"];
    const durLabel = [nameLabelMapper['Durationsec'] || "", "Duration"];
    const callInformation = [agentLabel, cusNameLabel, costLabel, startTimeLabel, endTimeLabel, durLabel];
    const goalStatusLabel = [nameLabelMapper['Goal_Achievement'] || "", "Goal Status"];
    const ratingLabel = [nameLabelMapper['Overall_Rating'] || "", "Rating"];
    const callSentimentLabel = [nameLabelMapper['Positive_Sentiment'] || "", "Call Sentiment"];
    const endedReasonLabel = [nameLabelMapper['Call_End_Reason'] || "", "Ended Reason"];
    const callAnalysis = [goalStatusLabel, ratingLabel, callSentimentLabel, endedReasonLabel];
    const [showLoading ,setShowLoading] = useState(false);

    const [formattedRowData, setFormattedRowData] = useState({});
    const [currentRating, setCurrentRating] = useState(0);
    const sentimentToRating = {
        Very_Poor:1,
        Poor: 2,
        Average: 2.5,
        Good: 4,
        Excellent: 5
    };
    const sentimentToCall = {
        Very_Negative:'fi fi-rs-sad',
        Negative : 'fi fi-rr-face-disappointed',
        Neutral:'fi fi-rr-meh',
        Positive:'fi fi-rr-smile',
        Very_Positive:'fi fi-rr-grin-alt'    
    }
    useEffect(() => {
        if (rowdata === undefined || rowdata === null || !rowdata || Object.keys(rowdata).length === 0 ) {
            setShowLoading(true);
            return ;
        }
        setShowLoading(true);
        let updatedData = {
            ...rowdata,
            [startTimeLabel[0]]: rowdata[startTimeLabel[0]] && Date.parse(rowdata[startTimeLabel[0]]) ? formatDateTime(convertUTCToLocalTime(rowdata[startTimeLabel[0]])) : "",
            [endTimeLabel[0]]: rowdata[endTimeLabel[0]] && Date.parse(rowdata[endTimeLabel[0]]) ? formatDateTime(convertUTCToLocalTime(rowdata[endTimeLabel[0]])) : "",  
            [costLabel?.[0] || ""]: rowdata?.[costLabel?.[0]] ? "$" + rowdata[costLabel[0]] : "$0",
            [goalStatusLabel?.[0] || ""]: rowdata?.[goalStatusLabel?.[0]] === "Yes" ? "Complete" : "Incomplete",
        };
        setFormattedRowData(updatedData);
        let index =  '';
        if (rowdata[ratingLabel[0]]) {
            index = rowdata[ratingLabel[0]].replaceAll(" ", "_");
        }
        let initialRating = sentimentToRating[index] || 0;
        setCurrentRating(initialRating);
        setShowLoading(false);
    }, [rowdata]);

    const ratingData = () => {
        const starStyle = { fontSize: '18px', margin: '2px', cursor: 'pointer' };
        return (
            <span>
                {[...Array(5)].map((_, index) => {
                    const starValue = index + 1;
                    const isHalfStar = currentRating === 2.5 && starValue === 3;

                    return (
                        <i
                            key={starValue}
                            className={
                                starValue <= currentRating
                                    ? 'fi fi-ss-star' // Full star
                                    : isHalfStar
                                    ? 'fi fi-ss-star-sharp-half-stroke' // Half star for Neutral
                                    : 'fi fi-rs-star' // Empty star
                            }
                            style={starStyle}
                            aria-hidden="true"
                        ></i>
                    );
                })}
            </span>
        );
    };


    const emoji = (value) =>{
        const starStyle = { fontSize: '14px', marginRight: '8px' };
        let index = '';
        if (value) {
            index = value.replaceAll(" ", "_");
        }          
        return (
            <span>
                <i className={sentimentToCall[index]} style={starStyle}></i> 
                {value}
            </span>     
        );
    }
    return (
        <Fragment>
            {!showLoading&& (
            <div className='overview-container'>
                <div className='overview-header'>
                    <span className='call-overview-info'>Call Information</span>
                </div>

                {callInformation.map((field, index) => (
                    <div className='calls-overview-div' key={index}>
                        <span className='calls-overview-fieldlabel'>{field[1]}</span>
                        <span className='calls-overview-fieldvalue'>
                            {formattedRowData[field[0]]}
                        </span>
                    </div>
                ))}

                <div className='overview-header'>
                    <span className='call-overview-info'>Call Analysis</span>
                </div>

                {callAnalysis.map((field, index) => (
                    <div className='calls-overview-div' key={index}>
                        <span className='calls-overview-fieldlabel'>{field[1]}</span>
                        <span className='calls-overview-fieldvalue'>
                            {field[1] === 'Rating' ? ratingData() : field[1] === 'Call Sentiment' ? emoji(formattedRowData[field[0]]) : formattedRowData[field[0]]}
                        </span>
                    </div>
                ))}
            </div>
            )}
        </Fragment>
    );
};

export default CallsOverview;
