import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { getCredentials, getCurrentPlanInfo, getLiceneInfo, getOrganizationInfo, getPlanPricing, isAgentAccount, updateBillInfo } from '../../Common/Appcommon';

const StepsContext = createContext();

const useStepsContext = () => useContext(StepsContext);

const StepsProvider = ({children}) => {

    /**
     * currentStep steps list
     * 
     *  1 - plans list page
     *  2 - billing page
     *  3 - confirm page
     *  4 - manage page
     *  5 - cancel page
     *  6 - current plan page
     *  7 - trail plan list page
     * 
     */
    const isAgency = isAgentAccount();
    const accountid = getOrganizationInfo("Account_ID");
    const appid = getOrganizationInfo("appid");
    const [isUsage, setIsUsage] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [noofMonth, setNoofMonth] = useState(1);
    const [showLoading, setShowLoading] = useState(false);
    const [stripePromise, setStripePromise] = useState(null);
    const [plansList, setPlansList] = useState([]);
    const [currentplan, setCurrentPlan] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState({});
    const [orgDetails, setOrgDetails] = useState({});
    const [licenseInfo, setLicenseInfo] = useState({});
    const [paymentDetails, setPaymentDetails] = useState({});
    const callPaymentFunc = useRef();

    useEffect(() => {
        const fetchData = async () => {
            setShowLoading(true);
            const plans = await getPlanPricing(isAgency);
            const stripe = await getCredentials(isAgency);
            const currentplan = await getCurrentPlanInfo(isAgency);
            const licenseInfo = await getLiceneInfo();
            let orgData = getOrganizationInfo("all");
            setOrgDetails(orgData);
            setCurrentPlan(currentplan);
            setStripePromise(stripe);
            setPlansList(plans);
            setLicenseInfo(licenseInfo);
            setPaymentDetails(licenseInfo[0]?.["paymentdetails"]);
            setShowLoading(false);
        }
        fetchData();
    }, []);
    
    const handleSubmitClick = async () => {
        await updateBillInfo(orgDetails, paymentDetails);
        if (callPaymentFunc.current) {
            setShowLoading(true);
            let result = await callPaymentFunc.current.processPayment();
            if(result){
                setCurrentStep(3);
            }
            setShowLoading(false);
        }
    };

    return (
        <StepsContext.Provider
            value={{
                isAgency,
                currentStep,
                setCurrentStep,
                plansList,
                noofMonth, 
                setNoofMonth,
                stripePromise,
                currentplan,
                setCurrentPlan,
                selectedPlan, 
                setSelectedPlan,
                orgDetails, 
                setOrgDetails,
                showLoading, 
                setShowLoading,
                paymentDetails,
                setPaymentDetails,
                accountid,
                appid,
                callPaymentFunc,
                isUsage,
                setIsUsage,
                handleSubmitClick
            }}
        >
            {children}
        </StepsContext.Provider>
    );
}

export { useStepsContext, StepsProvider };
