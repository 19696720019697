import React, { Fragment, useEffect, useState } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Input, Label } from "reactstrap";
import { getTemplatesFormDetails, handleErrorResponse, loadFormData, populateFormDetails, prepareLabelNameMapper, prepareTableData, showToast, getSessionValue, findTimeDiffAsync, confirmBox, formatDate, getTimeZonesList } from '../../Common/Appcommon';
import Loading from '../../Common/Loading';
import ListTable from '../../Common/ListTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Common from '../Common';
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import requestApiService from '../../services/requestApi.service';
import NoRecordFound from '../Common/NoRecordFound';
import aiData from "../../assets/utils/images/agent_no_records.png"
import reSendIcon from "../../assets/utils/images/reload.png";
import TooltipItem from '../../Common/ToolTip';
import { Icon } from '@iconify/react';
const Users = (props) => {
    let { accountInfo } = props;
    let data_row = {};
    let appid = "";
    if (accountInfo !== undefined) {
        data_row = accountInfo.data_row;
        appid = data_row.appid;
    }
    // let [userFormData, setUserFormData] = useState({});
    // let [userListFields, setUserListFields] = useState({});
    // let [nlMapper, setNLMapper] = useState({});
    const { control, handleSubmit, formState: { errors }, reset, trigger } = useForm();
    const [showLoading, setShowLoading] = useState(false);
    const [data, setData] = useState([]);
    const [column, setColumns] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [inputData, setInputData] = useState({});
    const [showButtonLoading, setshowButtonLoading] = useState(false);
    let [pflData, setPflData] = useState([]);
    let [proVal, setProVal] = useState(null);
    let [timeZoneList, setTimeZoneList] = useState([]);
    let [selectedTimeZone, setSelectedTimeZone] = useState({});
    useEffect(() => {
        (async () => {
            await loadSubAccountUsers();
            await getTimeZoneList();
        })();
        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    const resendInvite = async (row) => {
        setShowLoading(true);
        let { Name, Emailid } = row;
        await inviteUser(Name, Emailid, true);
    }

    const getTimeZoneList = async () => {
        let timeZoneData = await getTimeZonesList();
        let browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (browserTimeZone === "Asia/Calcutta") {
            browserTimeZone = "Asia/Kolkata";
        }

        // Calculate offset in hours and minutes
        const date = new Date();
        const offsetMinutes = -date.getTimezoneOffset(); // Negate to handle ahead/behind UTC
        const hours = Math.floor(offsetMinutes / 60);
        const minutes = Math.abs(offsetMinutes % 60);
        const formattedOffset = `${offsetMinutes >= 0 ? '+' : '-'}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        let selectedTime = "(GMT " + formattedOffset + ")-" + browserTimeZone;
        setSelectedTimeZone({ label: selectedTime, value: selectedTime });
        setTimeZoneList(timeZoneData);
    }

    const loadSubAccountUsers = async () => {
        setShowLoading(true);
        let formDetail = await populateFormDetails("table_6", appid);
        let { selectedTemplate } = await getTemplatesFormDetails(formDetail, "Active User");
        let usersData = await loadFormData("table_6", formDetail, selectedTemplate, appid);
        if (usersData.issuccess) {
            let { formData, listFields } = usersData;
            let nameLabelMapper = await prepareLabelNameMapper(formDetail[1]);
            for (let fi = 0; fi < formData.length; fi++) {
                let data = formData[fi];
                let accountLabel = nameLabelMapper['Account_Status'];
                let dataValue = data[accountLabel];
                let createdOn = data['Created On'];
                createdOn = createdOn.replace(" ", "T");
                createdOn = createdOn + "Z";
                let diffHrs = findTimeDiffAsync(createdOn);
                if (dataValue === "Invited" && diffHrs >= 48) {
                    dataValue = "Expired";
                    data[accountLabel] = dataValue;
                }
                formData[fi] = data;
            }
            let tableData = await prepareTableData(formData, listFields, false, formDetail[1]);
            let columns = tableData.columns;
            columns.push(
                {
                    name: "Action",
                    alias: 'access',
                    minWidth: '180px !important',
                    maxWidth: '0% !important',
                    cell: (row) => (
                        <div>
                        </div>
                    ),
                },
            );
            for (let cl = 0; cl < columns.length; cl++) {
                let column = columns[cl];
                if (column.alias === "access") {
                    column['cell'] = (row) => {
                        let dataValue = row.Account_Status;
                        let isExpired = dataValue === "Expired";
                        return (
                            <Fragment>
                                {isExpired && 
                                        <Button onClick={(e) => resendInvite(row)} className="user-account-btn theme-bg-info resend=icon">
                                    <TooltipItem
                                        id={'1'}
                                        text={"Resend invite email"}
                                        content={isExpired ? <Icon icon="fluent:mail-arrow-clockwise-24-regular"/> : ""}
                                    />
                                </Button>
                                } 
                                <Button 
                                    onClick={toggleEditModal(row)} 
                                    className={`user-account-btn theme-bg-info ${isExpired ? '' : 'ms-4'}`}>
                                    <TooltipItem
                                        id={'2'}
                                        text={"Edit user"}
                                        content={<Icon icon="bx:edit-alt"/>}
                                    />
                                </Button>
                                <Button onClick={(e) => deleteUser(row)} className="user-account-btn theme-bg-danger mt-1" >
                                    <TooltipItem
                                        id={'3'}
                                        text={"Delete user"}
                                        content={<i class="fi fi-rs-trash"></i>}
                                    />
                                </Button>
                            </Fragment>
                        )
                    }
                }else if(column.alias === "table_2_0_table_2_id"){
                    column['cell'] = (row) => {

                        const profileName = row.table_2_0_table_2_id_name !== undefined && row.table_2_0_table_2_id_name !== null && row.table_2_0_table_2_id_name !== "" ? (row.table_2_0_table_2_id_name.toLowerCase().includes("admin") ? "Admin" : "User") :"";
                        return <span>{profileName}</span>;
                    }
                } else {
                    column['cell'] = (row) => {
                        const value = row[column.alias];
                        if (value) {
                            const datePattern = /^\d{4}-\d{2}-\d{2}(\s\d{2}:\d{2}:\d{2})?$/; 
                            if (datePattern.test(value)) {
                                const date = new Date(value); 
                                if (!isNaN(date)) {
                                    const formattedDate = formatDate(date, "mmm dd, yyyy"); 
                                    return <span>{formattedDate}</span>;
                                }
                            } 
                        }
                        return <span>{value}</span>;
                    };
                }
                columns[cl] = column;
            }
            setData(tableData.data);
            setColumns(columns);
            // setUserFormData(formData);
            // setUserListFields(listFields);
            //setNLMapper(nameLabelMapper);
        }
        setShowLoading(false);
    }

    const toggleEditModal = (rowData) => () => {
        ftchPfl();
        setEdit(true);
        setModalOpen(true);
        let profileName = rowData['table_2_0_table_2_id_name'];
        if(profileName.toLowerCase().includes("admin")){
            profileName = "Admin";
        } else {
            profileName = "User";
        }
        let profileId = rowData['table_2_0_table_2_id'];
        setProVal({ table_2_id: profileId, Profile_Name: profileName });
        let timeZone = rowData['Time_Zone'];
        setSelectedTimeZone({ label: timeZone, value: timeZone });
        //setEditData(rowData);
        setInputData(rowData);
    }

    const deleteUser = async (data) => {
        let confirm = await confirmBox("Are you sure you want to delete?", "Press Delete to confirm", 'warning', 'Delete', 'Cancel');
        if (!confirm) {
            return;
        }
        let userEmailid = data.Emailid;
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "DELETE_USER",
            "usermailid": userEmailid
        };
        if (appid !== "") {
            params['invite_user_sub_account'] = appid;
            params['isSubAccount'] = true;
            params['sub_accountId'] = appid;
        }
        let response = await requestApiService.processServerRequest(params, "WebV2");
        if (response.issuccess) {
            showToast("User deleted successfully.");
            window.location.reload();
        } else {
            if (response.errormsg !== "") {
                let error = response.errormsg.replace(/<\/?[^>]+(>|$)/g, "");
                handleErrorResponse(error);
            }
        }
    }

    const toggleModal = () => {
        //let fromAddress = getOrganizationInfo("From_Address");
        //if (!isModalOpen && appid !== "" && (fromAddress === null || fromAddress === undefined || fromAddress === "")) {
        //    showToast("Action Required! Please configure Transactional Email in profile settings before sending invites.");
        //    return;
        //}
        //if (!isModalOpen) {
        setshowButtonLoading(false);
        setInputData({});
        reset();
        //}
        ftchPfl();
        setProVal({ table_2_id: 1, Profile_Name: 'Admin' });
        setModalOpen(!isModalOpen);
        setEdit(false);
    };
    const toggle = () => {
        setInputData({});
        toggleModal();
    }

    const handleInputChange = (e) => {
        let tmpInputData = { ...inputData };
        tmpInputData[e.target.name] = e.target.value;
        setInputData(tmpInputData);
    };

    const sendInviteUser = async () => {
        let isValidEmail = await trigger("userEmail");
        if (!isValidEmail) {
            return;
        }
        await inviteUser(inputData.Name, inputData.Emailid);
    }

    const inviteUser = async (userName, userEmail, isResend = false) => {
        if (proVal === null && !isResend) {
            showToast("ERROR! Select User's Profile Name", 'top-right', 'error');
            return;
        }
        let inviteEmail = getSessionValue('loginemail');
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": isEdit ? "UPDATE_INVITED_USER" : "INVITE_USER",
            "Invited_User": inviteEmail,
            "userName": userName,
            "userMail": userEmail,
            "profile_Data": proVal,
            "selectedTimeZone": selectedTimeZone.value
        };
        if (appid !== "") {
            params['invite_user_sub_account'] = appid;
            //params['isSubAccount'] = true;
        }
        if (isResend) {
            params["RESEND_INVITE"] = true;
        }
        setshowButtonLoading(true);
        let response = await requestApiService.processServerRequest(params, "WebV2");
        if (response.issuccess) {
            setInputData({});
            if(!isEdit){
                showToast("Invite Sent! Check the user’s inbox.");
            }else{
                showToast("User details updated successfully!");
            }
            await loadSubAccountUsers();
            if (!isResend) {
                toggleModal();
            }
        } else {
            if (response.errormsg !== "") {
                handleErrorResponse(response.errormsg);
            } else {
                handleErrorResponse("User not invited!");
            }
            setshowButtonLoading(false);
            setShowLoading(false);
        }
    }
    const ftchPfl = async() => {
        const Profiles = [
            { table_2_id: 1, Profile_Name: "Admin" },
            { table_2_id: 2, Profile_Name: "User" }
        ];
        setPflData(Profiles);
    }
    const handleCheckboxChange = (data) => {
        setProVal(data);
    };

    const onTimeZoneChange = (selectedZone) => {
        setSelectedTimeZone(selectedZone);
    }

    const getModalContent = () => {
        return (
            <>
                <Modal isOpen={isModalOpen}>
                    <ModalHeader toggle={toggle}>
                        {isEdit ? "Update User" : "Invite Users"}
                        <div className='md-2 del-subaccount-header'>
                            {!isEdit ? "Invite users to collaborate. Each invite is unique to an email address and will expire in 2 days." : ""}
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Fragment>
                            <form id="agency-form" name={'agency'} onSubmit={handleSubmit((data, event) => sendInviteUser())}>
                                <Label>Name</Label>
                                <Controller
                                    name="Name"
                                    control={control}
                                    rules={{ required: (inputData.Name === undefined || inputData.Name === "") ? "Please provide Username" : "" }}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            id="Name"
                                            autoComplete="off"
                                            placeholder="Enter User Name..."
                                            onChange={(e) => {
                                                handleInputChange(e);
                                                field.onChange(e);
                                            }}
                                            value={inputData.Name}
                                            className={`form-control mb-2 ${errors.Name ? 'is-invalid' : ''}`}
                                        />
                                    )}
                                />
                                {errors.Name && <em className="mb-2 error invalid-feedback">{errors.Name.message}</em>}

                                <Label>Email </Label>
                                <Controller
                                    name="Emailid"
                                    control={control}
                                    rules={{
                                        required: inputData.Emailid === undefined || inputData.Emailid === ""
                                            ? "Please provide Email"
                                            : false,  // If email is provided, no error
                                        pattern: {
                                            value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                                            message: "Please provide valid sender Email Address"
                                        }
                                    }}

                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            id="userEmail"
                                            type="email"
                                            autoComplete="off"
                                            placeholder="Enter Email Id..."
                                            onChange={(e) => {
                                                handleInputChange(e);
                                                field.onChange(e);
                                            }}
                                            value={inputData.Emailid}
                                            className={`form-control mb-2 ${errors.Emailid ? 'is-invalid' : ''}`}
                                            disabled={isEdit}
                                        />
                                    )}
                                />
                                {errors.Emailid && <em className="mb-2 error invalid-feedback">{errors.Emailid.message}</em>}
                                <Label>Time Zone</Label>
                                <Controller
                                    name="time_zone"
                                    control={control}
                                    rules={{ required: (selectedTimeZone === undefined || selectedTimeZone === "") ? "Please choose default Time Zone" : "" }}
                                    render={({ field }) => (
                                        <Select
                                            placeholder={'---Choose One---'}
                                            name="time_zone"
                                            id="time_zone"
                                            className={`basic-select`}
                                            classNamePrefix="sub-select wa-select"
                                            value={selectedTimeZone}
                                            onChange={e => {
                                                onTimeZoneChange(e);
                                            }}
                                            options={timeZoneList?.length ? timeZoneList : []}
                                            isDisabled={false} 
                                        />
                                    )}
                                />
                                {errors.time_zone && <em className="mb-2 error invalid-feedback">{errors.time_zone.message}</em>}
                                <Label>User Profile</Label>
                                <div className='inv-usr-pro-check mb-3'>
                                    {pflData.length > 0 && pflData.map((data, index) => {
                                        let isChecked = false;
                                        if(data['table_2_id'] === parseInt(proVal.table_2_id)){
                                            isChecked = true;
                                        }
                                        return (
                                            <Label className='inv-usr-chk-lbl' check key={index}>
                                                <input
                                                    type="radio"
                                                    className="form-check-input-custom"
                                                    id={`exampleCustomCheckbox${index}`} // Unique id for each checkbox
                                                    name="profile"
                                                    value={data}
                                                    checked={isChecked}
                                                    onChange={() => handleCheckboxChange(data)}
                                                />
                                                {data["Profile_Name"]}
                                            </Label>
                                        )
                                    })
                                    }
                                </div>
                                <b><Label>Profile & Permissions :</Label></b>
                                <div>
                                    <p>By default, the Admin profile has full access to the platform, while the User profile is restricted from accessing business configuration settings.</p>
                                </div>
                                <ModalFooter>
                                    <Loading showLoader={showButtonLoading} mainContent={
                                        <Fragment>
                                            <Button className='container-footer-save theme-bg-g'>
                                                {isEdit ? "Update User" : "Invite User"}
                                            </Button>
                                        </Fragment>
                                    } />
                                </ModalFooter>
                            </form>
                        </Fragment>
                    </ModalBody>

                </Modal>
            </>
        );
    }


    return (
        <Fragment>
            {appid !== "" &&
                <Fragment>
                    <div className='table-list'>
                        <div className='list-view-header w-100 mb-4 d-flex'>
                            <Row className='w-100'>
                                <Col md={6} className='rdw-image-left'>
                                    <div class="d-grid">
                                        <span class="heading">{"Users"}</span>
                                        <span class="header-desc">{""}</span>
                                    </div>
                                </Col>
                                <Col md={6} className='rdw-image-right'>
                                    <div className=''>
                                        <Button className='theme-bg-g' size="lg" color="dark" onClick={toggleModal}><FontAwesomeIcon icon={faPlus} /> Invite User</Button>

                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Loading showLoader={showLoading} mainContent={
                            <Fragment>
                                {data.length > 0 && column.length > 0 &&
                                    <ListTable data={data} columns={column} />
                                }
                                {
                                    !showLoading && data.length === 0 && <NoRecordFound
                                        img={aiData}
                                        isOnlyContent={true}
                                        content={"No Users Found."}
                                    />
                                }
                            </Fragment>
                        }
                        />
                    </div>
                </Fragment>
            }
            {appid === "" &&
                <Common title={"Users"}
                    titleContent={
                        <span>Users<span class="count">{data.length}</span></span>
                    }
                    titleBody={
                        <div className=''>
                            <Button size="lg" className='theme-bg-g' color="dark" onClick={toggleModal}><FontAwesomeIcon icon={faPlus} /> Invite User</Button>
                        </div>
                    }
                    mainContent={
                        <Fragment>
                            <div className='main-table-list'>
                                <Loading showLoader={showLoading} mainContent={
                                    <Fragment>
                                        {data.length > 0 && column.length > 0 &&
                                            <ListTable data={data} columns={column} />
                                        }
                                        {
                                            !showLoading && data.length === 0 && <NoRecordFound
                                                img={aiData}
                                                isOnlyContent={true}
                                                content={"No Users Found."}
                                            />
                                        }
                                    </Fragment>
                                }
                                />
                            </div>
                        </Fragment>
                    }
                />
            }
            {isModalOpen && getModalContent()}


        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
