import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import Loading from '../../Common/Loading';

const HeatmapChart = ({ chartData = [], Loader = true, defaultChartConfig = {}, tooltipLabel = "" }) => {
    const [chartOptionData, setChartOptionData] = useState({});
    const [chartSeriesData, setChartSeriesData] = useState([]);
    const [chartLabel, setChartLabel] = useState("");
    const [chartHeight, setChartHeight] = useState();
    const [chartWidth, setChartWidth] = useState();  

    useEffect(() => {
        if (chartData && defaultChartConfig && tooltipLabel) {
            setDataForChart(chartData, defaultChartConfig, tooltipLabel);
        }
    }, [chartData, defaultChartConfig, tooltipLabel]);

    const setDataForChart = (chartData, defaultChartConfig, tooltipLabel) => {
        let { type, toolbar, tooltipName, colorRange, height, width} = defaultChartConfig;
        if (!chartData[0] || !chartData[1] || !chartData[3]) {
            return;
        }
        let label = chartData[0] || [];
        let series = chartData[1]; 
        //let tooltipData = chartData[3]; 
        let tooltipFormat = {};
        if (tooltipName === "callHeatMap") {
            tooltipFormat = {
                enabled: true,
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    let value = series[seriesIndex][dataPointIndex];
                    let matchedRange = colorRange?.find(range => value >= range.from && value <= range.to);
                    let backgroundColor = matchedRange ? matchedRange.color : '#FFFFFF';
                    let category = w.config.xaxis.categories[dataPointIndex];
                    let totalCalls =w.config.tooltipData[seriesIndex]?.[dataPointIndex]?.[0] || 0;
                    let calls = w.config.tooltipData[seriesIndex]?.[dataPointIndex]?.[1] || 0;
                    let label = w.config.tooltipLabel;
                    return `
                        <div class="cus-tooltip-box">
                            <div class="cus-tooltip-header">
                                <strong>${label}</strong>
                                <span style="background-color: ${backgroundColor}; width: 15px; height: 15px; display: inline-block; margin-left: 8px; border-radius:50%;"></span>
                            </div>
                            <div class="cus-tooltip-body">
                                <span>Time: ${category}</span><br>
                                <span>Total Calls: ${totalCalls}</span><br>
                                <span>${label}: ${calls} (${value}%)</span><br>
                            </div>
                        </div>
                    `;
                },
            };
        }

        let options = {
            chart: {
                type: type || 'heatmap',
                toolbar: { show: !!toolbar },
            },
            plotOptions: {
                heatmap: {
                    shadeIntensity: 0.5,
                    colorScale: { ranges: colorRange || [] },
                    radius: 8,
                },
            },
            legend:{
                show:true,
                showForSingleSeries:true,
            },
            xaxis: {
                categories: label || [],
            },
            dataLabels: {
                enabled: true,
                formatter: function (value) {
                    return value === 0 ? '0%' : `${value}%`; 
                },
            },
            tooltip: tooltipFormat,
            tooltipData: chartData[3] || [],
            tooltipLabel : tooltipLabel.slice(tooltipLabel.indexOf('vs') + 3).trim()
        };

        setChartOptionData(options);
        setChartSeriesData(series);
        setChartLabel(tooltipLabel);
        setChartWidth(width);
        setChartHeight(height);
    };

    return (
        <div>
            <Loading
                showLoader={
                    Loader
                }
                mainContent={
                    <>
                        <div className="card widget-chart text-start">
                            <div className="widget-chart-content">
                                <span className="widget-subheading">{chartLabel}</span>
                                <ReactApexChart
                                    options={chartOptionData}
                                    series={chartSeriesData}
                                    type="heatmap"
                                    height={chartHeight}
                                    width={chartWidth}
                                />
                            </div>
                        </div>
                    </>
                }
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle,
});

const mapDispatchToProps = (dispatch) => ({
    setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeatmapChart);
