import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import { getLogoutURL, getSessionValue, parseJSON, validateLogin, handleErrorResponse } from "../../Common/Appcommon";
import requestApiService from "../../services/requestApi.service";

class Nav extends Component {
  constructor(props) {
    super(props);
    let menuList = getSessionValue('menus');
    let menus = (menuList !== '' && menuList !== undefined && menuList !== 'undefined' && menuList !== null) ? parseJSON(menuList) : [];
    this.state = {
      menus: menus,
      activeLinkTo: '/#',
      userProfileName: '',
    };
  }
  toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = this.props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  componentDidMount = async () => {
    if (window.performance) {
      let SubAccount = getSessionValue("isSubAccountView");
      if (SubAccount === "false" || SubAccount === undefined || SubAccount === null) {
        let isValidSession = await this.isValidSession();
        if (!isValidSession || isValidSession === undefined || isValidSession === "") {
          window.location = getLogoutURL();
        } else {
          await validateLogin("", false);
        }
      } else {
        await validateLogin("", false);
      }
    }
  }

  isValidSession = async () => {
    let res = "";
    let params = {
      "ENTITY": "session_check",
    }
    let response = await requestApiService.processServerRequest(params);
    if (response.issuccess) {
      res = response.data['isValidSession'];
    } else {
      handleErrorResponse(response.errormsg);
    }
    return res;
  }

  getActiveLinkLabel = (mainMenu) => {
    let hash = window.location.hash;
    let currect_menu_label = "";
    mainMenu && mainMenu.map((menu) => {
      if (menu.content) {
        menu.content && menu.content.map((submenu, index) => {
          if (hash.includes(submenu.subto)) {
            currect_menu_label = submenu.label
          }
        })
      } else {
        if (menu.to === hash) {
          currect_menu_label = menu.label
        }
      }
    });
    return currect_menu_label;
  }

  render() {
    let { menus } = this.state;
    if (menus.length === 0) {
      return <Fragment></Fragment>
    }
    let mainMenu = menus[0];

    return (
      <Fragment>
        <MetisMenu
          content={mainMenu}
          onSelected={this.toggleMobileSidebar}
          activeLinkFromLocation
          activeLinkLabel={this.getActiveLinkLabel(mainMenu)}
          className="vertical-nav-menu app-menu-sub-container"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />
      </Fragment>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}
const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));
