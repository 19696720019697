import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import SearchComponent from "./SearchComponent";
import { randstr, getContactLinkedData } from "../../Common/Appcommon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import "./assets/index.css";

const SearchPanel = ({ key,fieldDetails, onSearchClickRegister, preSearchComponents, forceFilter, filterType, actionType, searchStringType, type }) => {
  const filterHolderRef = useRef(null);
  const searchEl = useRef(null);
  preSearchComponents = preSearchComponents === undefined ? [] : preSearchComponents;
  forceFilter = forceFilter === undefined ? false : forceFilter;
  const [state, setState] = useState({
    showFilterList: false,
    searchInfoPrepared: false,
    allSearchInfo: [],
    filterSearchInfo: [],
    searchComponents: preSearchComponents,
    filterErrors : {},
    subtableValueOptions: []
  });
  const [count, setCount] = useState(1);

  useEffect(() => {
    (async () => {
      if(fieldDetails){
        await prepareSearchInfo(fieldDetails);
      }
    })();
    return () => {};
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (fieldDetails) {
        // Filter for relevant fields and create a list of promises
        const fetchPromises = fieldDetails
          .filter(
            (field) =>
              field[4] === "subtable" &&
              (field[2] === "table_81_0_table_81" ||
                field[2] === "table_82_0_table_82")
          )
          .map(async (field) => {
            const key = field[2];
            const data = await getContactLinkedData(field[35]?.[1] ?? ""); // Fetch the data
            return { key, data }; // Return as an object
          });
    
        // Wait for all promises to resolve
        const results = await Promise.all(fetchPromises);
    
        // Transform results into the desired object format
        const structuredData = results.reduce((acc, { key, data }) => {
          acc[key] = data; // Assign data to the corresponding key
          return acc;
        }, {});
    
        setState({
          ...state,
          subtableValueOptions: structuredData, // Store structured data in state
        });
      }
    };
    
  
    fetchData(); // Call the async function
  
    return () => {
      // Cleanup logic if needed
    };
  }, [fieldDetails]); // Add fieldDetails to the dependency array  

  useEffect(() => {
      return () => {};
  }, [preSearchComponents]);

  useEffect(() => {
    onSearchClickRegister(processSearchCallBacks);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const processSearchCallBacks = async (operation, customProps = {}) => {
    if (operation === "validateInput") {
      let errorPresented = false;
      let isConditionPresented = false;
      state.filterErrors = {};
      for (let index in state.searchComponents) {
        isConditionPresented = true;
        let details = state.searchComponents[index];
        let fd = details.fieldDetail;
        let changes = details.changes;
        let isError = false;
        details.errors = {};
        if (changes["value_0"] === "") {
          details.errors["value_0"] = true;
          isError = true;
        }
        if (changes["value_2"] === "") {
          details.errors["value_2"] = true;
          isError = true;
        }
        if (changes["value_3"] === "") {
          details.errors["value_3"] = true;
          isError = true;
        }
        if (isError) {
          errorPresented = true;
          details.errors["message"] = "Please provide a valid " + fd[3];
        }
        state.searchComponents[index] = details;
      }

      if(!isConditionPresented && forceFilter){
        errorPresented = true;
        state.filterErrors = {"message" : "Please provide a valid condition"};
        setState({ ...state });
        //return isConditionPresented;
      }
      if (errorPresented) {
        setState({ ...state });
      }

      return errorPresented;
    } else if ("getSearchString") {
      let modeOfSearch = customProps.modeOfSearch;
      let searchString = "";
      for (let index in state.searchComponents) {
        let details = state.searchComponents[index];
        let fd = details.fieldDetail;
        let changes = details.changes;
        let condition = details.condition === undefined ? "AND" : details.condition['condition_0']
        let operator = changes["operator_0"];
        let value = "empty";
        if(filterType === "trigger" && searchStringType !== "form"){
          searchString += "@ @";
        }
        if (
          fd[4] === "date" ||
          fd[4] === "Date_Time" ||
          (fd[4] === "form_history" &&
            (fd[2] === "createdon" ||
              fd[2] === "updatedon" ||
              fd[2] === "viewedon"))
        ) {
          value = "empty|empty";
          if (operator === "Before" || operator === "After"  || operator === "Below" || operator === "Above") {
            value = changes["value_0"] + "$" + changes["value_1"];
          } else if (operator === "Custom") {
            value = changes["value_2"] + "|" + changes["value_3"];
          } else if (changes["value_2"] !== undefined) {
            value = changes["value_2"] + "|" + changes["value_2"];
          }
          searchString +=
            "date@" + fd[1] + "@" + fd[2] + "@" + operator + "@" + value;
        } else if (
          fd[4] === "entity_group" ||
          fd[4] === "reference" ||
          (fd[4] === "form_history" &&
            (fd[2] === "table_6_0_createdusername" ||
              fd[2] === "table_6_1_updatedusername" ||
              fd[2] === "table_6_2_viewedusername"))
        ) {
          let refformname = fd[35][1];
          let nthinstance = fd[35][3];
          let reffieldname = fd[35][2];
          if (changes["value_0"] !== undefined) {
            value = changes["value_0"];
          }
          if(filterType === "trigger" && searchStringType !== "form"){
          searchString +=
            "nondate@" +
            fd[1] +
            "@" +
            fd[2] +
            "@" +
            operator +
            "@" +
            value;
          } else {
            searchString +=
            "nondate@" +
            refformname +
            "_" +
            nthinstance +
            "@" +
            reffieldname +
            "@" +
            operator +
            "@" +
            value;
          }
        } else if (fd[4] === "custom_filter"){
          searchString +=
            "spl@" + fd[2] + "@" + operator + "@" + changes["value_0"] + "@" + changes["value_1"];
        } else if (
          fd[4] === "subtable" && 
          (
            fd[2] === "table_81_0_table_81" || 
            fd[2] === "table_82_0_table_82"
          )
        ){
          let fieldname = fd[2];
          let subtoptions = state.subtableValueOptions[fieldname];
          let values = changes.value_0;
          let defaultValArr = values ? values.split(",") : [];
          let selectedOpt = [];
          for (let di = 0; di < defaultValArr.length; di++) {
            let dvalue = defaultValArr[di];
            for (let index in subtoptions) {
              let option = subtoptions[index];
              if (option.value === dvalue) {
                selectedOpt.push(option);
                //break;
              }
            }
          }
          searchString += "linkspl@" + fd[35][0] + "@" + fd[35][1] + "@" + operator + "@" + JSON.stringify(selectedOpt);
            // "linkspl@" + fd[3] + "@" + operator + "@" + changes["value_0"] + "@" + changes["value_1"];
        } else {
          if (changes["value_0"] !== undefined) {
            value = changes["value_0"];
          }
          searchString +=
            "nondate@" + fd[1] + "@" + fd[2] + "@" + operator + "@" + value;
        }
        searchString += "@" + condition + "#";
      }
      return searchString;
    }
  };

  const prepareSearchInfo = async (fd) => {
    let searchInfo = [];
    if(actionType === "auto"){
      //searchInfo = custom_filter_options;
    }
     /*searchInfo.push([
       "",
       "",
       "Record_Action",
       "Record Action",
       "custom_filter",
     ]);
     searchInfo.push(["", "", "Activities", "Activities", "custom_filter"]);
     searchInfo.push(["", "", "Calls", "Calls", "custom_filter"]);*/
    for (let findex = 0; findex < fd.length; findex++) {
      let fielddetails = fd[findex];
      let searchType = fielddetails[18];
      let isMappingEnabled = parseInt(fielddetails[31]);
      if(searchStringType === "form"){
        isMappingEnabled = true;
      }
      
      if (isMappingEnabled && (searchType === "ALL" || searchType === "Advanced") && type !== "outbound") {
        searchInfo.push(fielddetails);
      }
      if(fielddetails[4] === "subtable" && (fielddetails[2] === "table_81_0_table_81" || fielddetails[2] === "table_82_0_table_82")){
        fielddetails[3] = "Contact " + fielddetails[3];
        searchInfo.push(fielddetails);
      }
    }
    state.allSearchInfo = searchInfo;
    state.filterSearchInfo = searchInfo;
    setState({ ...state });
  };

  const onFilterSearch = async (e) => {
    let searchvalue = e.target.value;
    let filterSearchInfo = [];
    if (searchvalue === "") {
      state.filterSearchInfo = state.allSearchInfo;
      setState({ ...state });
    } else {
      for (let findex = 0; findex < state.allSearchInfo.length; findex++) {
        let fielddetails = state.allSearchInfo[findex];
        let fieldLabel = fielddetails[3];
        if (fieldLabel.toLowerCase().includes(searchvalue.toLowerCase())) {
          filterSearchInfo.push(fielddetails);
        }
      }
      state.filterSearchInfo = filterSearchInfo;
      setState({ ...state });
    }
  };
  const onFilterChange = async (e, fd) => {
    if (searchEl.current) {
      searchEl.current.value = "";
    }

    state.showFilterList = false;
    state.filterSearchInfo = state.allSearchInfo;
    state.filterErrors = {};
    state.searchComponents.push({ fieldDetail: fd, changes: {}, errors: {}, condition: {'condition_0': 'AND'} });
    setState({ ...state });
  };
  const onFilterFocus = async (e) => {
    const newCount = count + 1;
    setCount(newCount);
    if (!state.showFilterList && newCount % 2 !== 0) {
      state.showFilterList = true;
      setState({ ...state });
    }
  };
  const handleClickOutside = (event) => {
    if (
      state.showFilterList &&
      filterHolderRef.current &&
      !filterHolderRef.current.contains(event.target)
    ) {
      state.showFilterList = false;
      setState({ ...state });
    }
  };

  const onFilterRemove = async (e, index) => {
    state.searchComponents = state.searchComponents.filter(
      (details, i) => i !== index
    );
    setState({ ...state });
  };

  const onFilterAdd = async (e) => {
    state.searchComponents.push({ fieldDetail: [], changes: {}, errors: {}, condition: {'condition_0': 'AND'} });
    setState({ ...state });
  }

  const onFilterFieldChangeAction = async(fd, filterIndex) => {
    state.searchComponents[filterIndex].fieldDetail = fd;
    setState({ ...state });
  }

  const onchangeCondition = async (value, index) => {
    let condition = {};
    condition["condition_0"] = value;
    state.searchComponents[index]['condition'] = condition;
    setState({ ...state });
  }

  const onValueChange = async (mapper, index) => {
    state.searchComponents[index].changes = mapper;
  };

  const getDefaultValue = (fd, changes) => {
    if (changes && Object.keys(changes).length > 0) {
      return changes;
    } else if (fd[4] === "custom_filter" && fd[2] === "Record_Action") {
      return {
        operator_0: "touched",
        operator_1: "user_and_system",
        operator_2: "in_the_last",
        value_0: "2",
        value_1: "days",
      };
    } else if (fd[4] === "custom_filter" && fd[2] === "Activities") {
      return {
        operator_0: "without_open_activities",
      };
    } else if (fd[4] === "Int" || fd[4] === "Currency") {
      return {
        operator_0: "is",
      };
    } else if (
      fd[4] === "date" ||
      fd[4] === "Date_Time" ||
      (fd[4] === "form_history" &&
        (fd[2] === "createdon" || fd[2] === "updatedon" || fd[2] === "viewedon"))
    ) {
      return {
        operator_0: "After",
        value_0: "2",
        value_1: "days",
      };
    } else if (fd[4] === "ComboBox") {
      return {
        operator_0: "is",
      };
    } else if (
      fd[4] === "reference" ||
      fd[4] === "entity_group" ||
      (fd[4] === "form_history" &&
        (fd[2] === "table_6_0_createdusername" ||
          fd[2] === "table_6_1_updatedusername" ||
          fd[2] === "table_6_2_viewedusername"))
    ) {
      return {
        operator_0: "is",
      };
    } else {
      return {
        operator_0: "is",
      };
    }
  };

  return (
    <div class="filter-view-container">
      <div
        class={cx("popover-view-content filter-view-popup", {
          "filter-focus": state.showFilterList,
        })}
      >
        <div className={cx("popper-search-block", {
            "hide": state.searchComponents.length > 0 && filterType === "trigger"
          })}
        >
          <form
            noValidate=""
            name="frmSearchView"
            id="frmSearchView"
            class="ng-pristine ng-valid ng-touched"
          >
            <div className="d-flex-alone filter-dropdown">
              <input
              type="text"
              onFocus={(e) => {
                onFilterFocus(e);
              }}
              onKeyUp={(e) => {
                onFilterSearch(e);
              }}
              onClick={(e) => {
                onFilterFocus(e);
              }}
              name="filterView"
              autoComplete="off"
              ref={searchEl}
              className={`form-control white-bg ng-pristine ng-valid ng-touched ${state.filterErrors['message'] ? 'is-invalid' : ''}`}
              placeholder="Select…"
            />
             <div className='d-flex-alone filter-panel wa-select__control' onFocus={(e) => {onFilterFocus(e);}}onKeyUp={(e) => {onFilterSearch(e);}}onClick={(e) => {onFilterFocus(e);}}>
              <div className="fp-dd-divider"></div>
              <div className="custom-dropdown-arrow">
                <FontAwesomeIcon icon={faAngleDown} />
              </div>
            </div>
            </div>
            {state.filterErrors["message"] && (
          <em class="error invalid-feedback filter-componen errors">
            {state.filterErrors["message"]}
          </em>
        )}
          </form>
        </div>
        <div>
          <div
            ref={filterHolderRef}
            class={cx("popover-list", { show: state.showFilterList })}
          >
            <ul>
              {state.filterSearchInfo.map((fd, index) => {
                return (
                  <li
                    onClick={(e) => {
                      onFilterChange(e, fd);
                    }}
                    class={cx("show-on-hover template-list-items")}
                  >
                    <a>
                      <i class="icon-check" hidden=""></i>
                      {fd[3]}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div class="filter-view-content">
        {state.searchComponents.map((details, index) => {
          return (
            <SearchComponent
              key={randstr("sc_")}
              filterIndex={index}
              details={details}
              removeFilterComp={onFilterRemove}
              addFilterComp={onFilterAdd}
              conditionChangeComp={onchangeCondition}
              defaultValueMapper={
                getDefaultValue(
                  details.fieldDetail ?? {},
                  details.changes ?? [],
                )
              }
              filterType={filterType}
              searchStringType={searchStringType}
              filterInfo={state.filterSearchInfo}
              pushChanges={onValueChange}
              fieldChangeAction={onFilterFieldChangeAction}
              isLastFilter={index === state.searchComponents.length - 1} 
              subtableValueOptions={state.subtableValueOptions}
            />
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(SearchPanel);
