import React, { Fragment } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Input, Label, } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import cx from "classnames";

import Common from '../Common';
import ListTable from '../../Common/ListTable';
import SubaccountDetails from './SubaccountDetails';
import aiData from "../../assets/utils/images/agent_no_records.png"
import { setSessionValue, isMobile, getSessionValue, prepareCustomTableData, getOrganizationInfo } from '../../Common/Appcommon';
import requestApiService from '../../services/requestApi.service';
import Loading from '../../Common/Loading';
import NoRecordFound from '../Common/NoRecordFound';
import Select from "react-select";

class SubAccounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue:null,
            data: [],
            columns: [],
            showDetailed: false,
            accountInfo: {},
            isModalOpen: false,
            subAccountName: '',
            errorMessage: '',
            showLoader: false,
            showButtonLoading: false,
            searchStr: '',
            options: [
                {value: 'all_account', label: 'All Account'},
                { value: 'status,Active', label: 'Active' },
                { value: 'status,Deactive', label: 'In Active' },
                { value: 'type,Trial', label: 'Trial' }
            ],
        }
    }

    setShowLoaderStatus = async (status = false) => {
        this.setState({
            showLoader: status
        })
    }

    componentDidMount = async () => {
        this.setState({selectedValue:this.state.options[0]})
        await this.setShowLoaderStatus(true);
        await this.loadSubAccountData();
        await this.setShowLoaderStatus();
    };

    onViewAccountAction = (row) => {
        let { data_row } = row;
        let appid = data_row.appid;
        setSessionValue("isSubAccountView", true);
        setSessionValue("subAccountAppid", appid);
        window.location = "#/login_check";
    }

    onViewAccountSettings = (row) => {
        this.setState({
            accountInfo: row,
            showDetailed: true
        })
    }

    loadSubAccountData = async (searchValue) => {
        let agentId = getOrganizationInfo("Account_ID");
        let { selectedValue } = this.state;
        if (agentId !== "" && agentId !== undefined) {
            let params = {
                "ENTITY": "AIPROCESSOR",
                "OPERATION": "LOAD_SUBACCOUNTS",
                "AGENT_ID": agentId,
                "SearchStr": searchValue,
                "filter":selectedValue
            }
            let formresponse = await requestApiService.processServerRequest(params, "WebV2");
            if (formresponse.issuccess) {
                let data = formresponse.data;
                let subaccounts = data[0] ?? [];
                let columndetails = data[1] ?? [];
                await this.preparesubAccountData(subaccounts, columndetails);
            }
        }
    }

    /*loadSubAccountData = async () => {
        let formDetail = await populateFormDetails("table_201");
        let { selectedTemplate } = await getTemplatesFormDetails(formDetail);
        let SubAccountsData = await loadFormData("table_201", formDetail, selectedTemplate);
        if (SubAccountsData.issuccess) {
            let { formData, listFields } = SubAccountsData;
            this.preparesubAccountData(formData, listFields);
        }
    }*/
    preparesubAccountData = async (formData, listFields) => {
        let data = [];
        let columns = [];
        let tableData = await prepareCustomTableData(formData, listFields, true, false, this.onViewAccountSettings);
        data = tableData.data;
        data = data.map((fd, index) => {
            fd["view_as_agency"] = 'Access';
            fd["account_settings"] = 'Manage';
            return fd;
        });
        columns = tableData.columns;

        columns.push(
            {
                name: "Action",
                selector: 'access',
                minWidth: '105px !important',
                cell: (row) => (
                    <div>
                        <Button onClick={() => this.onViewAccountAction(row)} className="access-account-btn theme-bg-g">
                            <span>{row['view_as_agency']}</span>
                            <i class="ml-2 fi fi-bs-arrow-up-right-from-square"></i>
                        </Button>
                    </div>
                ),
                style: {
                    padding: 0,
                    margin: 0,
                    justifyContent: 'center'
                }
            },

        );
        this.setState({
            data, columns
        });
    }

    getModalContent = () => {
        let { isModalOpen, errorMessage, showButtonLoading } = this.state;
        return (
            <>
                <Modal isOpen={isModalOpen}>
                    <ModalHeader toggle={this.closeModal}>
                        Add Sub Account
                    </ModalHeader>
                    <ModalBody>
                        <Fragment>
                            <Label>Provide a Name</Label>
                            <Input className="mb-2" onChange={e => { this.handleInputChange(e) }} maxLength={100} required value={this.state.subAccountName}></Input>
                        </Fragment>
                        <div>
                            {errorMessage && <em className="error">{errorMessage}</em>}</div>
                    </ModalBody>
                    <ModalFooter>
                        <Loading showLoader={showButtonLoading} mainContent={
                            <Fragment><Button className='container-footer-save theme-bg-g' onClick={e => { this.addsubAccount(e) }}>
                                Create Sub Account
                            </Button>
                            </Fragment>
                        } />
                    </ModalFooter>
                </Modal>
            </>
        );
    }

    handleInputChange = (e) => {
        let value = e.target.value;
        const regex = /^[a-zA-Z0-9 _-]*$/;
        if (regex.test(value)) {
            this.setState({
                subAccountName: value,
                errorMessage: (value !== undefined && value !== "") ? '' : this.state.errorMessage
            });
        } else {
            this.setState({
                errorMessage: "Special characters are not allowed."
            });
        }
    }

    addsubAccount = async (e) => {
        e.preventDefault();
        let { subAccountName } = this.state;
        if (subAccountName === "" || subAccountName === undefined) {
            this.setState({
                errorMessage: "Sub Account Name is required."
            })
        } else {
            await this.setShowLoaderStatus(true);
            this.setState({
                errorMessage: "",
                showButtonLoading: true,
            })
            let params = {
                "ENTITY": "AIPROCESSOR",
                "OPERATION": "ADD_SUBACCOUNT",
                "accountname": subAccountName,
                "isAgencyInvite": true,
                "agency_id": getOrganizationInfo("Account_ID"),
                "agency_appid": getSessionValue('appid')
            }
            let formresponse = await requestApiService.processServerRequest(params, "WebV2");
            if (formresponse.issuccess) {
                setSessionValue('menus', JSON.stringify(formresponse.data[1]));
                this.closeModal();
                window.location.reload();
                await this.loadSubAccountData();
            }
            await this.setShowLoaderStatus();
        }
    }
    handlesubAccount = () => {
        this.setState((prevState) => ({
            isModalOpen: !prevState.isModalOpen,
        }));
    }

    closeModal = (name) => {
        this.setState((prevState) => ({
            isModalOpen: !prevState.isModalOpen,
            errorMessage: '',
            showButtonLoading: false,
            subAccountName: '',
        }));
    }

    backToViewAction = async () => {
        this.setState({
            accountInfo: {},
            showDetailed: false
        })
        await this.loadSubAccountData();
    }

    handleChange = async (selectedOption) => {
        this.setState({ selectedValue: selectedOption }, async () => {
            await this.loadSubAccountData();
        });
    };    
    getTitleBody = (showDetailed) => {
        if (showDetailed) {
            return "";
        }
        
        return <div className='d-flex align-center justify-end w-100'>
            <Col md={5}  className={cx("", {
                "hide" : isMobile()
            })}>
                <div className='le-form-row'>
                    <div className="input-with-icon">
                        <span className="search-icon">
                            <i class="icon-color fi fi-rs-search"></i>
                        </span>
                        <Input
                            id="userName"
                            autoComplete="off"
                            placeholder="Search by name"
                            onChange={(e) => {
                                this.handleSearch(e)
                            }}
                            value={this.state.searchStr}
                            className='search-input'
                        />
                    </div>
                </div>
            </Col>
            <Col md={5} className={cx("pl-2", {
                "hide" : isMobile()
            })}>
                <Select
                    name="selected_form"
                    id="selected_form"
                    placeholder="All Account"
                    className="basic-select"
                    classNamePrefix="sub-select wa-select"
                    value={this.state.selectedValue} 
                    options={this.state.options} 
                    onChange={this.handleChange}
                />
            </Col>
            <Col md={3} className='pl-2 voice-drop-wid'>
                <Button onClick={this.handlesubAccount} size="lg" className='theme-bg-g compact-padding' color="dark"><FontAwesomeIcon icon={faPlus} /> Add Sub Account</Button>
            </Col>
        </div>;
    }

    handleSearch = async (e) => {
        let value = e.target.value;
        this.setState({
            searchStr: value
        })
        await this.loadSubAccountData(value)
    }

    render() {
        let { data, columns, showDetailed, accountInfo, isModalOpen } = this.state;


        return (
            <Fragment>
                <Common
                    title={"Sub Accounts"} titleContent={
                        <span>{showDetailed ? <span className='custom-list-title'>{"Sub Account / "}<span className='highlight-name'>{accountInfo.account_name}</span></span> : "Sub Accounts"}</span>
                    }
                    titleBody={this.getTitleBody(showDetailed)}
                    mainContent={
                        <Fragment>
                            <div className='d-grid  w-100 '>

                                {!showDetailed && <div className='subaccount-list'>
                                    <Fragment>
                                        <div className='list-view-header mt-2 w-100 mb-2 d-flex hide'>
                                            <Row className='w-100'>
                                                <Col md={6} className='rdw-image-left'>
                                                    <div class="d-grid">
                                                        <span class="heading">{"Subaccounts"}</span>
                                                        <span class="header-desc">{""}</span>
                                                    </div>
                                                </Col>
                                                <Col md={6} className='rdw-image-right'>

                                                </Col>
                                            </Row>
                                        </div>

                                        {
                                            !this.state.showLoader ? data.length === 0 ? <NoRecordFound
                                            img={aiData}
                                            isOnlyContent={true}
                                            content={"No Users Found."}
                                        /> :
                                            <ListTable data={data} columns={columns} onSort={this.onSort} /> : (data.length === 0 ? <NoRecordFound img={aiData} isOnlyContent={true} content={"No Users Found."}/>:<ListTable data={data} columns={columns} onSort={this.onSort} />)
                                        }
                                    </Fragment>

                                </div>}
                                {showDetailed && <div className='col-md-12'>
                                    <SubaccountDetails backToViewAction={this.backToViewAction} openSubAccountView={this.onViewAccountAction} accountInfo={accountInfo} />
                                </div>}
                            </div>
                            {
                                isModalOpen && this.getModalContent()
                            }
                        </Fragment>
                    } />


            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubAccounts);
