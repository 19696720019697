import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { Button, Container } from "reactstrap";
import Common from "../Common";
import CurrentPlan from "./CurrentPlan";
import { useStepsContext } from "../Subscription/context";
import SubscriptionBilling from "../Subscription/SubscriptionBilling";
import SubscriptionPlans from "../Subscription/SubscriptionPlans";
import SubscriptionConfirm from "../Subscription/SubscriptionConfirm";
import CancelPage from "./CancelPage";
import { updateBillInfo } from "../../Common/Appcommon";

const Usage = ({isUsage}) => {
    const { 
        currentStep, 
        setCurrentStep, 
        showLoading, 
        paymentDetails, 
        setIsUsage, 
        callPaymentFunc, 
        setShowLoading, 
        orgDetails,
        handleSubmitClick
    } = useStepsContext();

    useEffect(() => {
        if(isUsage && currentStep !== 4){
            setIsUsage(isUsage);
            if(Object.keys(paymentDetails).length > 0){
                setCurrentStep(6);
            }else{
                setCurrentStep(7);
            }
        }
    }, [paymentDetails]);

    let title = "Usage";
    let buttonlabel = "Update Plan";
    if(currentStep === 2 || currentStep === 7 || !isUsage){
        title = "Subscription";
        buttonlabel = "Subscribe Plan";
    }

    return(
        <Common
            title={title}
            titleContent={
                <span>
                    {title}
                </span>
            }
            titleBody={
                <Fragment>
                    {
                        (currentStep === 4 || currentStep === 2) && (
                            <Button
                                onClick={e => handleSubmitClick()} 
                                className="theme-bg-g m-1"
                            >
                                {buttonlabel}
                            </Button>
                        )
                    }
                </Fragment>
            }
            mainLoader={showLoading}
            mainContent={
                <Fragment>
                    <Container fluid>
                        {
                            currentStep === 1 && <SubscriptionPlans/>
                        }
                        {
                            currentStep === 2 && <SubscriptionBilling/>
                        }
                        {
                            currentStep === 3 && <SubscriptionConfirm/>
                        }
                        {
                            currentStep === 4 && <SubscriptionBilling/>
                        }
                        {
                            currentStep === 5 && <CancelPage/>
                        }
                        { 
                            currentStep === 6 && <CurrentPlan/>
                        }
                        {
                            currentStep === 7 && <SubscriptionPlans/>
                        }
                    </Container>
                </Fragment>
            }
        />
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Usage);