export const DateFilterOptions = [
    {
        label : "Today",
        value : "today"
    },
    {
        label : "Yesterday",
        value : "yesterday"
    },
    {
        label : "Last 7 Days",
        value : "last_7_days"
    },
    {
        label : "Last 30 Days",
        value : "last_30_days"
    },
    {
        label : "This Month",
        value : "this_month"
    },
    {
        label : "Last Month",
        value : "last_month"
    },
    {
        label : "Custom",
        value : "custom"
    },
];

export const barChartsList = [
    {
        label: 'Calls by Type', value: 'total_calls_by_source', isHorizontal : false, type: 'total_calls_by_source', colordistributed: false, tooltip: 'count', operation: 'TOTAL_CALLS_BY_SOURCE'
    },
    {
        label: 'Calls by Status', value: 'total_calls_by_status', isHorizontal : false, type: 'total_calls_by_status', colordistributed: false, tooltip: 'count', operation: 'TOTAL_CALL_BY_STATUS'
    },
    {
        label: 'Calls by Goal Status', value: 'goal_achieved', isHorizontal : true, type: 'goal_achieved', colordistributed: false, tooltip: 'count', operation: 'GOAL_ACHIEVED'
    },
    {
        label: 'Add-on Spent', value: 'total_spent', isHorizontal : true, type: 'total_spent', colordistributed: false, tooltip: 'dollar', operation: 'GET_TOTAL_SPENT'
    },
    
    
];

export const graphListColumns = [
    {
        label: "Call Ended by Reason",
        operation: "GET_CALL_ENDED_BY_REASON",
        columns: [
            {
                name: "Reason",
                selector: row => row["reason"],
                sortable: true,
                width: '50%',
            },
            {
                name: "Call Count",
                selector: row => row["count"],
                sortable: true,
                width: '25%',
            },
            {
                name: "Call Minutes",
                selector: row => row["minutes"],
                sortable: true,
                width: '25%',
            },
        ],
    },
    {
        label: "Call Minutes by Agents",
        operation: "GET_CALL_MINUTES_BY_ASSISTANT",
        columns: [
            {
                name: "AI Agent Name",
                selector: row => row["Agent_Name"],
                sortable: true,
                width: '50%',
            },
            {
                name: "Call Count",
                selector: row => row["count"],
                sortable: true,
                width: '25%',
            },
            {
                name: "Call Minutes",
                selector: row => row["minutes"],
                sortable: true,
                width: '25%',
            },
        ],
    },
];