import { useWebCallContext } from './Common/WebCallContext'; // Import the context hook
import call_cancel from './Components/Agents/assets/images/call-cancel.svg';
import { Popover } from 'reactstrap';
import React ,{ useEffect } from 'react';

const WebCallPopOver = () => {
    const {
        isPopoverOpen, 
        toggleFun, 
        agentname, 
        stopCall, 
        isCallReady,
        curDuaration, 
    } = useWebCallContext(); 

    useEffect(() => {
    }, [isPopoverOpen, isCallReady, stopCall,toggleFun]);

    return (
        <>
            <div className='app-footer-right agent-footer'>
                <div id="WebCall"></div>
                {isCallReady && (
                    <Popover
                        className="rm-max-width"
                        innerClassName="agent-popover"
                        placement="bottom-start"
                        fade={false}
                        hideArrow
                        isOpen={isPopoverOpen}
                        target="WebCall"
                        toggle={() => toggleFun &&  toggleFun()}
                    >
                        <div className='agent-popover-heading'>
                            <span className='agents-call-details'>
                                <span className="agent-call-icon"><i class="fi fi-ss-user-headset"></i></span> 
                                {agentname}
                            </span>
                            <span>{curDuaration || "0:00"}</span>
                        </div>
                        <div className='agent-popover-footer'>
                            <img className="agent-cancel-call" src={call_cancel} alt="Call Cancel" onClick={() => stopCall && stopCall()}/> 
                        </div>
                    </Popover>
                )}
            </div>
        </>
    );
};

export default WebCallPopOver;
