import React, { Fragment, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import Common from '../Common';
import Filter from "./Filter";
import { Col, CardBody } from "reactstrap";
import { subDays } from "date-fns";
import requestApi from "../../services/requestApi.service";
import ABTestingTable from './ABTestingTable';
import { ABTesting } from './constant';
import "./index.css";
import { isMobile } from "../../Common/Appcommon";

const ABTest = () => {
    const [agentDetail, setAgentDetail] = useState([1,2]);
    const startDate = subDays(new Date(), 29); 
    const [demo, setDemo] = useState(false); 
    const endDate = new Date();
    const [showLoader, setShowLoader] = useState(false); 
    const [dateFilter, setDateFilter] = useState({
        Start_DateTime: [startDate, endDate],
        noofdays: 30,
    });
    const [tableData,setTableData] = useState([]);
    const [dateRange,setDateRange] = useState([]);
    const [mobile, setIsMobile] = useState(false);

    const generateParams = (defaultConfig) => {
        return {
            ENTITY: "AIPROCESSOR",
            OPERATION: defaultConfig.operation,
            FILTEROPTION: dateFilter, 
            FORMNAME: "table_37",
            demo:demo,
            agentDetail: agentDetail, 
            isOtherApiReq: true,
        };
    };


    const loadABTestData = async () =>  {
        setShowLoader(true);
        let params = generateParams(ABTesting[0]);
        if(agentDetail.length > 0){
            try {
                const response = await requestApi.processServerRequest(params);
                if (response.issuccess) {
                    const data = response.data;
                    const dateRange = data[0];
                    setDateRange(dateRange);    
                    const remainingData = data.slice(1);
                    setTableData(remainingData);
                }
                let mobile = isMobile();
                setIsMobile(mobile);
                setShowLoader(false);
            } catch (error) {
                console.error("Error fetching chart data:", error);
                setShowLoader(false); 
            }
        }
    }

    const handelCompareAgentValue = (selectedAgent,dateFilter) =>{
        setAgentDetail(selectedAgent);
        setDateFilter(dateFilter)
    }

    const handleDemo = (ischecked) =>{
        setDemo(ischecked);
    }

    const setMainContent = () => {
        return (
            <Col md={12}>
                {mobile && 
                    getTitleBody()
                }
                <CardBody className='mt-1'>
                    <ABTestingTable tableData={tableData} tableConfig={ABTesting[0]} Loader={showLoader} dateRange={dateRange}/>
                </CardBody>
            </Col>
        );
    };

    const getTitleBody = () =>{
        return (
            <Filter
                getCompareAgentvalue={handelCompareAgentValue}
                needAgentCompareFilter={true} 
                needCallFilter={false}             
                needAgentFilter={false}           
                needDateFilter={true}
                needDemo={true}
                getDemo={handleDemo}
                setDemo={demo}
            />
        );
    }
    useEffect(() => {
        if(agentDetail.length > 0 ){
            loadABTestData();
        }
    }, [agentDetail, dateFilter,demo]);

    return (
        <Fragment>
            <Common
                demoPopup = {
                    demo
                }
                demoHandler = {
                    handleDemo
                }
                titleBody={
                    !mobile &&  getTitleBody()
                }
                title={
                    "Calls Analytics"
                } 
                titleContent={
                    "A/B Testing"
                }
                mainContent={
                    setMainContent()
                }
            />
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = (dispatch) => ({
    setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ABTest);
