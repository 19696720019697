import React, { useState, Fragment, useEffect } from 'react';
import Common from '../Common';
import { Card, CardBody } from "reactstrap";
import { formatFileSize, handleErrorResponse, populateFormDetails, showToast } from '../../Common/Appcommon';
import requestApi from "../../services/requestApi.service";
import ImportUploader from './Step1';
import ImportFieldMapper from './Step2';
import DoImport from './Step3';
import ExportPanel from './ExportPanel';

const ImportExport = (props) => {
    let contactform = "table_74";
    const [showLoading, setShowLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [fileDetails, setFileDetails] = useState({});
    const [importParams, setImportParam] = useState({});
    const [step, setCurrentStep] = useState(1);
    const inString = process.env.REACT_APP_MAX_IMPORT_SIZE_FORMAT;
    let [formD, setFormDetail] = useState([]);
    const hiddenFileInput = React.useRef(null);
    const [parsedData, setParsedData] = useState([]);
    const [selectedList, setSelectedList] = useState([]);

    useEffect(() => {
        (async () => {
            let formDetail = await populateFormDetails(contactform);
            setFormDetail(formDetail);
        })();
    }, []);

    const getHeaderTitleBody = () => {
        return (<div></div>)
    }

    const onUploadImportFile = (event) => {
        hiddenFileInput.current.click();
    };

    const handleOnFileChange = async (event) => {
        const fileUploaded = event.target.files[0];
        let fileName = fileUploaded.name;
        let fileSize = fileUploaded.size;
        try {
            let maxSize = parseInt(process.env.REACT_APP_MAX_IMPORT_SIZE, 10);
            if (fileSize <= (maxSize*16)) {
                await processUploadImportFile(fileUploaded);
            } else {
                showToast("File must be withing " + inString, "top-right", "warning");
            }
        } catch (err) {
            showToast(err);
        }
    }

    const processUploadImportFile = async (file) => {
        setIsUploading(true);
        let formInfo = formD[0];
        let params = {
            "ENTITY": "IMPORT",
            "FORMNAME": contactform,
            "MODULENAME": formInfo[0],
            "command": "parse",
            "fileparamname": "importfilename",
            "file": file,
        }
        let response = await requestApi.processServerRequest(params);
        if (response.issuccess) {
            let importData = response.data;
            setParsedData(importData);
            setIsUploading(false);
            let recordCount = importData[3]['recordCount'];
            if(recordCount > 5000){
                showToast('Your CSV file contains more than 5000 records. Please provide a file with less than 5000 records.');
                return;
            }if(recordCount <= 0){
                showToast('An empty CSV file is not allowed. Please provide a valid file with data.');
                return;
            }
            let fileName = file.name;
            let fileSize = formatFileSize(file.size);
            let fileType = file.type;
            fileType = fileType.split("/");
            fileType = fileType[1];
            setFileDetails({ name: fileName, size: fileSize, type: fileType });
        } else {
            setIsUploading(false);
            handleErrorResponse(response.errormsg);
        }
    }

    const onContactListChange = (isSelected, selectedList) => {
        setSelectedList(selectedList);
    }

    const onContinueClick = (e) => {
        setShowLoading(true);
        setCurrentStep(2);
        setShowLoading(false);
    }

    const onBackAction = (step) => {
        setCurrentStep(step);
    }

    const onImportComplete = () => {
        setParsedData({});
        setImportParam({});
        setFileDetails({});
        setCurrentStep(1);
    }

    let attributes = { fieldLabel: "Contact List", fieldName: "table_81_0_table_81", fieldType: "subtable", formname: "table_74", fLength: 40, custom_property: "table_81_0_table_81", tag_formname: "table_81" };
    let filesAccept = "text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    return (
        <Fragment>
            <Common title={"Contacts"}
                titleContent={
                    <span>Import / Export Contacts</span>
                }
                titleBody={getHeaderTitleBody()}
                mainContent={
                    <Fragment>
                        <div className='import-container d-flex pl-3 pr-2 pt-2 w-100'>
                            <div className='import-panel col-md-8 pr-2'>
                                <Card className='card-br'>
                                    <CardBody className=''>
                                        <span className='heading'>Import Contacts</span>
                                        <div className='header-desc pt-2 mb-3'>Import your contacts using a CSV file.</div>
                                        <div className='import-step-indicator d-flex justify-content-around align-items-start'>
                                            <div class={`step-item text-center ${step === 1 ? 'active' : ''} ${step !== 1 ? 'completed' : ''}`}>
                                                <div class="step-item-number">
                                                    {step !== 1 && <i class="fi fi-rr-check"></i>}
                                                    {step === 1 && 1}
                                                </div>
                                                <div class="">Upload File</div>
                                            </div>
                                            <div class="flex-grow-1 px-2 mt-2"><hr /></div>
                                            <div class={`step-item text-center ${step === 2 ? 'active' : ''} ${step === 3 ? 'completed' : ''}`}>
                                                <div class="step-item-number">
                                                    {step === 3 && <i class="fi fi-rr-check"></i>}
                                                    {step !== 3 && 2}
                                                </div>
                                                <div class="">Map Fields</div>
                                            </div>
                                            <div class="flex-grow-1 px-2 mt-2"><hr /></div>
                                            <div class={`step-item text-center ${step === 3 ? 'active' : ''} `}>
                                                <div class="step-item-number">3</div>
                                                <div class="">Import</div>
                                            </div>
                                        </div>
                                        <div className='import-border rounded '>
                                            {step === 1 && <ImportUploader
                                                onContactListChange={onContactListChange}
                                                onUploadImportFile={onUploadImportFile}
                                                isUploading={isUploading}
                                                fileDetails={fileDetails}
                                                hiddenFileInput={hiddenFileInput}
                                                handleOnFileChange={handleOnFileChange}
                                                onContinueClick={onContinueClick}
                                                showLoading={showLoading}
                                                setFileDetails={setFileDetails}
                                            />}
                                            {step === 2 && <ImportFieldMapper
                                                parsedData={parsedData}
                                                onBackAction={onBackAction}
                                                setCurrentStep={setCurrentStep}
                                                setImportParam={setImportParam}
                                                setError={handleErrorResponse}
                                            />}
                                            {step === 3 && <DoImport
                                                importParams={importParams}
                                                setCurrentStep={setCurrentStep}
                                                onImportComplete={onImportComplete}
                                                selectedList={selectedList}
                                            />}
                                        </div>
                                        
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='export-panel col-md-4 pl-3'>
                                <Card className='card-br'>
                                    <CardBody className=''>
                                        <span className='heading'>Export contacts</span>
                                        <ExportPanel />
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </Fragment>
                }
            />
        </Fragment>
    )
}

export default ImportExport;