import React, { useEffect, Fragment, useRef, useState } from "react";
import cx from "classnames";
import DatePicker from "react-datepicker";
import {formatDate} from "../../../Common/Appcommon";

const CratioDatePicker = (props) => {
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    (async () => {
      if (typeof props.value == "string") {
        if (props.value == "current") {
          setSelectedDate(new Date());
        } else if (props.value != "" && props.value != "0000-00-00") {
          setSelectedDate(new Date(props.value));
        }
      }else if(typeof props.value == "object" && props.value instanceof Date){
        setSelectedDate(props.value);
      }
    })();
  }, []);

  const onChange = async (date) => {
    if (props.onChangeEvent) {
      props.onChangeEvent(false, date, props.attributes);
    }
    setSelectedDate(date);
  };

  let dataValue = "";
  if (selectedDate instanceof Date) {
    dataValue = formatDate(selectedDate, "yyyy-mm-dd");
  } else {
    dataValue = selectedDate != null ? selectedDate : "";
  }

  return (
    <div
      className={cx(props.customClass)}
      {...props.attributes}
      data-value={dataValue}
    >
      <DatePicker
        dateFormat="MMMM dd, yyyy"
        placeholderText="mm/dd/yyyy"
        className={`form-control ${props.inputClass}`}
        selected={selectedDate}
        onChange={onChange}
        popperPlacement="top"
      />
    </div>
  );
};

export default CratioDatePicker;
