import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Container, Form, Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import cx from "classnames";
import Common from "../Common";
import {
    getTimeZonesList,
    populateFormDetails,
    parseJSON,
    getObjectByValue,
    prepareSearchConditionComponent,
    getContactLinkedData,
    isMobile,
    showToast,
    confirmBoxWithImage,
    getLoginUserInfo,
    formatDateTime,
    getSessionValue,
    formatDate
} from "../../Common/Appcommon";
import requestApiService from "../../services/requestApi.service";
import { userActionList } from "./assets/Constant";
import VariationContainer from "./VariationContainer";
import VariablesContainer from "./VariablesContainer";
import SearchPanel from "../Search/SearchPanel";
import outCollImg from "../../assets/utils/images/out_call.png";
import darkoutCollImg from "../../assets/utils/images/dark_out_call.png";
import { useTheme } from "../../Context/ThemeContext";

const OutboundOperations = (props) => {
    const contactform = "table_74";
    //const listform = "table_81";
    const tagname = "table_82";
    const formname = "table_83";
    const matchAll = true;
    const { type } = props;
    const { formId } = useParams();
    const subBtnRef = useRef();
    const getVariationRef = useRef();
    const getVariablesRef = useRef();
    let searchActionCB = false;
    const [contactFields, setContactFields] = useState([]);
    const [contactFormFields, setContactFormFields] = useState([]);
    const [formData, setFormData] = useState([]);
    const [timeZones, setTimeZones] = useState([]);
    const [numberList, setNumberList] = useState([]);
    const [sComponents, setSearchComponents] = useState([]);
    const [mainLoader, setMainLoader] = useState(true);
    const [knowledgeInfo, setKnowledgeInfo] = useState();
    const [tagsOptions, setTagsOptions] = useState([]);
    const [isSendSetLive, setIsSendSetLive] = useState(false);
    const [error, setError] = useState([]);
    //const [listOptions, setListOptions] = useState([]);
    const { handleSubmit, control, formState: { errors } } = useForm();
    const { theme } = useTheme();
    let proName = getSessionValue("agent_provider_name");
    const customStyles = {
        menu: (base) => ({
            ...base,
            zIndex: 1050, // Adjust based on your layout
        }),
    };

    useEffect(() => {
        const fetchTimeZones = async () => {
            let numbersList = await loadPhoneNumbers();
            setNumberList(numbersList);
            let formdetails = await loadContactFormDetails();
            let timezones = getTimeZonesList();
            setTimeZones(timezones);
            await getFieldOptions();
            if (formId) {
                await loadRowData(numbersList, formdetails);
            } else {
                loadDefaultData(timezones)
            }
            setMainLoader(false);
        };
        fetchTimeZones();
    }, []);

    const getFieldOptions = async () => {
        //let listOptions = await getContactLinkedData(listform) ?? [];
        let tagOptions = await getContactLinkedData(tagname) ?? [];
        //setListOptions(listOptions);
        setTagsOptions(tagOptions);
    }

    const loadRowData = async (numbersList, formdetails) => {
        let params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_FORM_RECORD",
            FORMNAME: formname,
            FORMID: formId,
            type: type
        }
        let response = await requestApiService.processServerRequest(params);
        if (response.issuccess) {
            let data = response.data;
            if (Object.keys(data).length > 0) {
                let Followups = data.Followups;
                let Work_Hours = data.Work_Hours;
                let Additional_Configs = data.Additional_Configs;
                let Knowledge_Variables = data.Knowledge_Variables;
                let filters = data.filters ?? "";
                Followups = parseJSON(Followups);
                Work_Hours = parseJSON(Work_Hours);
                Knowledge_Variables = parseJSON(Knowledge_Variables);
                Additional_Configs = parseJSON(Additional_Configs);
                data["trigger_at"] = getObjectByValue(userActionList, data.triggerAt) ?? "";
                data["phone_number"] = getObjectByValue(numbersList, data.table_247_0_table_247_id) ?? "";
                //data["Knowledge_Variables"] = Knowledge_Variables;
                let System_Prompt = null;
                if (data.phone_number && data.phone_number.System_Prompt) {
                    System_Prompt = data.phone_number.System_Prompt;
                }
                if (type === "campaign") {
                    Work_Hours.launch_date = new Date(Work_Hours.launch_date).toString();;
                }
                setKnowledgeInfo(System_Prompt);
                let searchcomponent = [];
                if (filters !== "") {
                    searchcomponent = prepareSearchConditionComponent(filters, formdetails);
                }
                let mergedData = { ...data, ...Followups, ...Work_Hours, ...Additional_Configs };
                setFormData(mergedData);
                setTimeout(() => {
		setSearchComponents(searchcomponent);
		}, 500);
            } else {
                // If data is empty, load the 'new' URL
                window.location.hash = `#/${type}/new`;
            }
        }
    }

    const loadDefaultData = (timeZones) => {
        let usertimezone = getLoginUserInfo("Time_Zone");
        setFormData((prevFormData) => ({
            ...prevFormData,
            ["time_zone"]: { label: usertimezone, value: usertimezone },
            ["start_time"]: "11:00",
            ["end_time"]: "16:00",
            ["launch_date"]: null,
        }));
    }

    const loadContactFormDetails = async () => {
        let skipFields = [
            `${contactform}_id`,
            'Lead_ID',
            'wsapp_recent_activity',
            'wsapp_convo_status',
            'wsapp_unread_count',
            'wsapp_optin_status',
            'wsapp_recent_msg_id',
            'wsapp_enabled',
            'wsapp_blocked',
            'wsapp_opt',
            'wsapp_last_delivered',
            'wsapp_last_read',
            'wsapp_last_replied',
            'wsapp_last_sent_msg_id',
            'wsapp_last_sent_msg_id',
            'wsapp_completed_campaign'
        ];
        let fieldList = [
            { value: "fallback", label: "Use Fallback" }
        ];
        let formDetail = await populateFormDetails(contactform);
        let fieldDetails = formDetail[1];
        fieldDetails && fieldDetails.map((field) => {
            let fieldName = field[2];
            let fieldLabel = field[3];
            let fieldType = field[4];
            let isConfigHide = parseInt(field[10]);
            let isViewHide = parseInt(field[11]);
            if (!isConfigHide && !isViewHide) {
                if (fieldType !== 'subtable' && fieldType !== 'reference_entityid' && fieldType !== "reference_group" && fieldType !== "form_history") {
                    if (!skipFields.includes(fieldName)) {
                        if (fieldLabel !== undefined && fieldName !== undefined) {
                            fieldList.push({
                                value: fieldName, label: fieldLabel
                            })
                        }
                    }
                }
            }
        });
        setContactFields(fieldList);
        setContactFormFields(formDetail);
        return formDetail;
    }

    const loadPhoneNumbers = async () => {
        let response = [];
        let params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_PHONENO_OPTIONS",
            FORMNAME: formname,
        }
        let result = await requestApiService.processServerRequest(params);
        if (result.issuccess) {
            response = result.data;
        }
        return response;
    }

    const handleSwitchChange = (name) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: !prevFormData[name],
        }));
    };

    const handleChangeDate = (date) => {
        const fdate = new Date(date);
        let formattedUTCDate = formatDate(fdate,"yyyy-mm-dd") ;
        setFormData((prevFormData) => ({
            ...prevFormData,
            ["launch_date"]: formattedUTCDate,
        }));
    }

    const handleSelectChange = (name, option, formname = "") => {
        if (name === "phone_number") {
            setKnowledgeInfo(option.System_Prompt ?? null);
        }
        if (formname !== "") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: {
                    ...(prevFormData[name] || {}), // Ensure `prevFormData[name]` is an object
                    [formname]: option, // Add or update the nested property
                },
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: option,
            }));
        }
    };

    const processSendandSetLive = async () => {
        let isValidSearch = await searchActionCB("validateInput");
        if (!isValidSearch) {
            let filters = await searchActionCB("getSearchString", {
                modeOfSearch: matchAll ? "AND" : "OR",
            });
            let contactcount = 0;
            if (filters !== "") {
                if (type === "campaign") {
                    let params = {
                        ENTITY: "AIPROCESSOR",
                        OPERATION: "GET_CONTACTS_BY_FILTERS",
                        FORMNAME: contactform,
                        filters: filters,
                        countonly: true,
                        needContatcFilters:formData?.['action_1'] !== undefined ? formData['action_1'] : false,
                    }
                    let response = await requestApiService.processServerRequest(params);
                    if (response.issuccess) {
                        let data = response.data;
                        contactcount = parseInt(data[0][0]);
                    }
                }
                setIsSendSetLive(true);
                let title = "You’re All Set!";
                let message = "";
                let confimbtn = "";
                let cancelbtn = "Cancel";
                if (type === "campaign") {
                    confimbtn = "Start/ Schedule Campaign";
                    if (contactcount === 0) {
                        title = "No Recipients Found!";
                        confimbtn = "Go to Campaign";
                        message = "There are no recipients matching the criteria you have set for this campaign. Please adjust your conditions and try again.";
                        cancelbtn = false;
                    } else {
                        message = "Your outbound call campaign is set up and ready to be sent or scheduled.";
                    }
                } else {
                    confimbtn = "Start Automation";
                    message = "Your outbound call automation is configured and ready to launch.";
                    if (formData["Status"] === "Active") {
                        title = "Stop Automation";
                        message = "Are you sure you want to pause your active automation?";
                        confimbtn = "Pause Automation";
                    }
                }
                let confirm = await confirmBoxWithImage(title, message, theme === 'dark-theme' ? darkoutCollImg : outCollImg, confimbtn, cancelbtn);
                if (confirm && ((type === "campaign" && contactcount > 0) || type !== "campaign")) {
                    if (subBtnRef.current) {
                        subBtnRef.current.click();
                    }
                } else {
                    setIsSendSetLive(false);
                }
            }
        }
    }

    const handleBtnClick = async (name) => {
        if (name === "cancel") {
            window.location.hash = `#/${type}s`;
        } else if (name === "save") {
            if (subBtnRef.current) {
                subBtnRef.current.click();
            }
        } else if (name === "send") {
            await processSendandSetLive();
        }
    }

    const onInputChange = (e) => {
        let { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }

    const getActionKeys = (obj) => {
        return Object.keys(obj)
            .filter(key => key.startsWith("action_"))
            .reduce((acc, key) => {
                acc[key] = obj[key];
                return acc;
            }, {});
    };

    const constructFormData = () => {
        let Work_Hours = {};
        let formdata = {};
        let add_conf = {};

        for (let key in formData) {
            if (key === "time_zone" || key === "start_time" || key === "end_time" || key === "isWorkHoursActive" || key === "launch_date") {
                Work_Hours[key] = formData[key];
            } else if (key === "goal_complete" || key === "goal_incomplete" || key === "encryption_key") {
                add_conf[key] = formData[key];
            } else {
                formdata[key] = formData[key];
            }
        }

        // Merge additional configurations from `getActionKeys`
        add_conf = { ...add_conf, ...getActionKeys(formdata) };
        // Add `Work_Hours` and `Additional_Configs` to `formdata`
        formdata["Work_Hours"] = Work_Hours;
        formdata["Additional_Configs"] = add_conf;

        return formdata;
    };

    const onSearchClickRegister = (callback) => {
        searchActionCB = callback;
    }

    const saveFormData = async () => {
        let formdata = constructFormData();
        if (getVariationRef.current) {
            let variationvalues = getVariationRef.current.returnVariationValues();
            formdata["Followups"] = variationvalues;
        }
        if (getVariablesRef.current) {
            let variablesvalues = getVariablesRef.current.returnVariablesValues();
            formdata["Knowledge_Variables"] = variablesvalues;
        }

        if (type === "campaign" && !formData["launch_date"]) {
            setError((prevFormData) => ({
                ...prevFormData,
                ["launch_date"]: {
                    ...(prevFormData["message"] || {}), // Ensure `prevFormData[name]` is an object
                    ["message"]: "Invalid or missing launch date", // Add or update the nested property
                },
            }));
            return;
        }
        let isValidSearch = await searchActionCB("validateInput");
        if (!isValidSearch) {
            let filters = await searchActionCB("getSearchString", {
                modeOfSearch: matchAll ? "AND" : "OR",
            });
            if (filters !== "") {
                formdata["filters"] = filters;
            }
            const fdate = new Date(formdata['Work_Hours']['launch_date']);
            let formattedUTCDate = formatDate(fdate,"yyyy-mm-dd") ;
            formdata['Work_Hours']['launch_date'] = formattedUTCDate;
            let params = {
                ENTITY: "AIPROCESSOR",
                OPERATION: "SAVE_OUTBOUND_DETAILS",
                FORMINPUT: formdata,
                type: type,
                FORMNAME: formname,
                FORMID: formId,
                isSendSetLive: isSendSetLive,
            }
            let response = await requestApiService.processServerRequest(params);
            if (response.issuccess) {
                let msg = "Saved Successfully";
                if (type === "campaign" && isSendSetLive) {
                    msg = "Scheduled Successfully";
                } else if (type === "automation" && isSendSetLive) {
                    if (formData.Status === "Inactive") {
                        msg = "Start Successfully";
                    } else if (formData.Status === "Active") {
                        msg = "Stoped Successfully";
                    }
                }
                if (proName.toLocaleLowerCase() !== "vapi") {
                    msg += ". Can't schedule right now.";
                }
                showToast(msg);
                window.location.hash = `#/${type}s`;
            }
        }
    }

    /**
     * Renders a container with operation buttons.
     * Buttons and their properties (label, icon, etc.) are dynamically generated using a map.
     * 
     * @function getButtonsContainer
     * @returns {JSX.Element} A fragment containing the operation buttons.
     */
    const getButtonsContainer = () => {
        let isMobileDev = isMobile();
        return (
            <Fragment>
                <div className={cx("operation-buttons-container mt-2 d-flex", { "pt-5": isMobileDev })}>
                    <div className={cx("me-1 mb-2", { "w-50": isMobileDev })}>
                        <Button
                            name={"cancel"}
                            onClick={() => handleBtnClick("cancel")}
                            className={cx("badge badge-theme badge-btn custom-display w-100")}
                        >
                            <i className={"fi fi-rr-cross-circle icon-with-txt"}></i>
                            <span>{"Cancel"}</span>
                        </Button>
                    </div>
                    <div className={cx("ms-1 mb-2", { "w-50": isMobileDev })}>
                        <Button
                            name={"save"}
                            onClick={() => handleBtnClick("save")}
                            className={cx("badge badge-theme badge-btn custom-display w-100")}
                        >
                            <i className={"fi fi-rr-bookmark icon-with-txt"}></i>
                            <span>{"Save & Close"}</span>
                        </Button>
                    </div>
                    {
                        !isMobileDev && proName.toLowerCase() !== "" &&
                        <div>
                            <Button
                                name={"send"}
                                onClick={() => handleBtnClick("send")}
                                className={"btn-hover-shine theme-bg-g custom-display me-3 ms-2"}
                            >
                                <i className={"fi fi-rr-paper-plane icon-with-txt"}></i>
                                <span>{type === "automation" ? (formData["Status"] === "Active" ? "Stop Automation" : "Save & Start Automation") : "Start / Schedule Campaign"}</span>
                            </Button>
                        </div>
                    }
                </div>
                {
                    isMobileDev && proName.toLowerCase() !== "" &&
                    <div>
                        <Button
                            name={"send"}
                            onClick={() => handleBtnClick("send")}
                            className={"btn-hover-shine theme-bg-g custom-display me-3 w-100"}
                        >
                            <i className={"fi fi-rr-paper-plane icon-with-txt"}></i>
                            <span>{type === "automation" ? ("Save & Start Automation") : "Start / Schedule Campaign"}</span>
                        </Button>
                    </div>
                }
            </Fragment>
        );
    };

    const CustomComponent = (name) => {
        if (name === "Followups") {
            let mainContent = parseJSON(formData["Followups"] ?? "");
            let variations = mainContent && mainContent["variations"] ? mainContent["variations"] : [];
            let isEnabled = mainContent && mainContent["followup_enabled"] ? mainContent["followup_enabled"] : false;
            return (
                <VariationContainer
                    control={control}
                    ref={getVariationRef}
                    isEnabled={isEnabled}
                    variationsList={variations}
                    maxCount={5}
                />
            )
        } else if (name === "Knowledge" && knowledgeInfo) {
            const regex = /\{\{(.*?)\}\}/g;
            const matches = [...knowledgeInfo.matchAll(regex)];
            const values = matches.map(match => match[1]);
            if (values) {
                const formdata = formData["Knowledge_Variables"] && formData["Knowledge_Variables"] !== "null" ? formData["Knowledge_Variables"] : "";
                if (!formId || (formId && formdata !== "")) {
                    return (
                        <VariablesContainer
                            control={control}
                            ref={getVariablesRef}
                            formdata={parseJSON(formdata)}
                            list={values}
                            formFields={contactFields}
                        />
                    )
                }
            }

        } else if (name === "filters") {
            const hasFields = contactFormFields && contactFormFields[1];
            const isFormInvalid = !formId || (formId && sComponents.length > 0);
            if (hasFields && isFormInvalid) {
                return (
                    <SearchPanel
                        fieldDetails={contactFormFields[1]}
                        onSearchClickRegister={onSearchClickRegister}
                        preSearchComponents={sComponents}
                        forceFilter={true}
                        filterType={"trigger"}
                        actionType={"user"}
                        type={"outbound"}
                    />
                )
            }
        }

        return null;
    }


    const getMainContainer = () => {
        let stepindex = 0;
        return (
            <Fragment>
                <Container fluid>
                    <div className="outbound-config">
                        <Form onSubmit={handleSubmit(saveFormData)} className={cx("", { "pb-10p": !isMobile() }, { "pb-25p": isMobile() })}>
                            <div className="m-3 vertical-timeline-without-image">
                                <div className="outbound-section row mt-5 me-5">
                                    <div className="group-header d-flex">
                                        <div className="outbound-stepnumber">{stepindex = stepindex + 1}</div>
                                        <span className="ms-4">{type === "campaign" ? "Name Your Campaign" : "Name Your Automation"}</span>
                                    </div>
                                    <div className="ms-4">
                                        <Row>
                                            <Col md={10}>
                                                <label className="mt-2 mb-1" htmlFor={"Name"}>Provide a Name</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className={`col-md-10`}>
                                                <Controller
                                                    name={"Name"}
                                                    control={control}
                                                    rules={{
                                                        required: !formData["Name"] ? "This field is required" : false,
                                                    }}
                                                    render={({ field }) =>
                                                        <input
                                                            {...field}
                                                            type={"text"}
                                                            name={"Name"}
                                                            className={`form-control ${errors["Name"] ? 'is-invalid' : ''}`}
                                                            placeholder={"Name ..."}
                                                            value={formData["Name"] || ""}
                                                            onChange={(e) => {
                                                                field.onChange(e.target.value)
                                                                onInputChange({
                                                                    target: { name: "Name", value: e.target.value },
                                                                })
                                                            }
                                                            }
                                                            autoComplete="off"
                                                        />
                                                    }
                                                />
                                                {
                                                    errors["Name"] && (
                                                        <em className="error invalid-feedback d-flex">{errors["Name"].message}</em>
                                                    )
                                                }
                                            </div>
                                        </Row>
                                    </div>
                                    {false && <div className="ms-4">
                                        <Row>
                                            <Col md={10}>
                                                <label className="mt-2 mb-1" htmlFor={"Description"}>Description</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className={`col-md-10`}>
                                                <Controller
                                                    name={"Description"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <textarea
                                                            {...field}
                                                            name={"Description"}
                                                            className="form-control"
                                                            placeholder={"Enter Description..."}
                                                            value={formData["Description"] || ""}
                                                            onChange={(e) =>
                                                                onInputChange({
                                                                    target: { name: "Description", value: e.target.value },
                                                                })
                                                            }
                                                            autoComplete="off"
                                                        />
                                                    )
                                                    }
                                                />
                                                {
                                                    errors["Description"] && (
                                                        <em className="error invalid-feedback d-flex">{errors["Description"].message}</em>
                                                    )
                                                }
                                            </div>
                                        </Row>
                                    </div>}
                                </div>
                                {
                                    type === "automation" &&
                                    <div className="outbound-section row mt-5 me-5">
                                        <div className="group-header d-flex">
                                            <div className="outbound-stepnumber">{stepindex = stepindex + 1}</div>
                                            <span className="ms-4">{type === "campaign" ? "Set action type and trigger preference" : "Set Action and Trigger"}</span>
                                        </div>
                                        <div className="ms-4">
                                            <Row>
                                                <Col md={10}>
                                                    <label className="mt-2 mb-1" htmlFor={"Name"}>Choose a Trigger</label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <div className={`col-md-10`}>
                                                    <Controller
                                                        name={"Name"}
                                                        control={control}
                                                        rules={{
                                                            required: !formData["Name"] ? "This field is required" : false,
                                                        }}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                options={userActionList}
                                                                onChange={(e) => handleSelectChange("trigger_at", e)}
                                                                value={formData["trigger_at"]}
                                                                classNamePrefix="wa-select"
                                                                className="basic-select"
                                                                menuPlacement={"auto"}
                                                                styles={customStyles}
                                                            />
                                                        )
                                                        }
                                                    />
                                                    {
                                                        errors["trigger_at"] && (
                                                            <em className="error invalid-feedback d-flex">{errors["trigger_at"].message}</em>
                                                        )
                                                    }
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                }
                                <div className="outbound-section row mt-5 me-5">
                                    <div className="group-header d-flex">
                                        <div className="outbound-stepnumber">{stepindex = stepindex + 1}</div>
                                        <span className="ms-4">{type === "campaign" ? "Select Recipients" : "Define Trigger Conditions"}</span>
                                    </div>
                                    <div className="ms-4">
                                        <Row>
                                            <Col md={10}>
                                                <label className="mt-2 mb-1" htmlFor={"filters"}>
                                                    Choose the contact lists or tags you want to target for this  {type === "campaign" ? " campaign." : " automation."}
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className={`col-md-10`}>
                                                {
                                                    CustomComponent("filters")
                                                }
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                                <div className="outbound-section row mt-5 me-5">
                                    <div className="group-header d-flex">
                                        <div className="outbound-stepnumber">{stepindex = stepindex + 1}</div>
                                        <span className="ms-4">{"Assign Phone Number and AI Agent"}</span>
                                    </div>
                                    <div className="ms-4">
                                        <Row>
                                            <Col md={10}>
                                                <label className="mt-2 mb-1" htmlFor={"phone_number"}>Choose a Phone Number</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={10}>
                                                <Controller
                                                    name={"phone_number"}
                                                    control={control}
                                                    rules={{
                                                        required: !formData["phone_number"] ? "This field is required" : false,
                                                    }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            options={numberList}
                                                            onChange={(e) => handleSelectChange("phone_number", e)}
                                                            value={formData["phone_number"]}
                                                            classNamePrefix="wa-select"
                                                            className="basic-select"
                                                            menuPlacement={"auto"}
                                                            styles={customStyles}
                                                        />
                                                    )
                                                    }
                                                />
                                                {
                                                    errors["phone_number"] && (
                                                        <em className="error invalid-feedback d-flex">{errors["phone_number"].message}</em>
                                                    )
                                                }
                                            </Col>
                                            <Fragment>
                                                {
                                                    proName.toLowerCase() === "bland" &&
                                                    <Fragment>
                                                        <Col md={10}>
                                                            <label className="mt-2 mb-1" htmlFor={"encryption_key"}>Encryption Key (If phone number is Twilio)</label>
                                                        </Col>
                                                        <Col md={10}>
                                                            <Controller
                                                                name={"encryption_key"}
                                                                control={control}
                                                                rules={{
                                                                    required: false,
                                                                }}
                                                                render={({ field }) =>
                                                                    <input
                                                                        {...field}
                                                                        type={"text"}
                                                                        name={"encryption_key"}
                                                                        className={`form-control ${errors["encryption_key"] ? 'is-invalid' : ''}`}
                                                                        placeholder={"Encryption key ..."}
                                                                        value={formData["encryption_key"] || ""}
                                                                        onChange={(e) => {
                                                                            field.onChange(e.target.value)
                                                                            onInputChange({
                                                                                target: { name: "encryption_key", value: e.target.value },
                                                                            })
                                                                        }
                                                                        }
                                                                        autoComplete="off"
                                                                    />
                                                                }
                                                            />
                                                        </Col>
                                                    </Fragment>
                                                }
                                            </Fragment>
                                        </Row>
                                        <Row>
                                            <Col md={10}>
                                                {
                                                    CustomComponent("Knowledge")
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="outbound-section row mt-5 me-5">
                                    <div className="group-header d-flex">
                                        <div className="outbound-stepnumber">{stepindex = stepindex + 1}</div>
                                        <span className="ms-4">{"Schedule Follow-Up Actions"}</span>
                                    </div>
                                    <div className="ms-4">
                                        <Row>
                                            <Col md={10}>
                                                {
                                                    CustomComponent("Followups")
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="outbound-section row mt-5 me-5">
                                    <div className="group-header d-flex">
                                        <div className="outbound-stepnumber">{stepindex = stepindex + 1}</div>
                                        <span className="ms-4">{"Set After-Call Actions"}</span>
                                    </div>
                                    <div className="ms-4">
                                        <Row>
                                            <Col md={10}>
                                                <Row className="mt-2 mb-4" >
                                                    <Col lg={10} className="align-content-end me-4">
                                                        <label htmlFor={"action_1"}>Automatically unsubscribe a contact if call sending fails, and avoid calling unsubscribed numbers.</label>
                                                    </Col>
                                                    <Col lg={1}>
                                                        <Switch
                                                            onColor={"#034737"}
                                                            onChange={() => handleSwitchChange("action_1")}
                                                            checked={formData["action_1"]}
                                                            checkedIcon={
                                                                <div className='on-switch-btn'>
                                                                    On
                                                                </div>
                                                            }
                                                            uncheckedIcon={
                                                                <div className='off-switch-btn'>
                                                                    Off
                                                                </div>
                                                            }
                                                            width={60}
                                                            height={25}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2 mb-2">
                                                    <Col lg={10} className="align-content-end me-4">
                                                        <label htmlFor={"action_2"}>Automatically assign tags to contacts based on the Call Goal Status.</label>
                                                    </Col>
                                                    <Col lg={1}>
                                                        <Switch
                                                            onColor={"#034737"}
                                                            onChange={() => handleSwitchChange("action_2")}
                                                            checked={formData["action_2"]}
                                                            checkedIcon={
                                                                <div className='on-switch-btn'>
                                                                    On
                                                                </div>
                                                            }
                                                            uncheckedIcon={
                                                                <div className='off-switch-btn'>
                                                                    Off
                                                                </div>
                                                            }
                                                            width={60}
                                                            height={25}
                                                        />
                                                    </Col>
                                                </Row>
                                                {
                                                    formData["action_2"] &&
                                                    <Row>
                                                        <Col lg={6}>
                                                            <label className="mt-2 mb-1" htmlFor={"goal_complete"}>On Goal Complete</label>
                                                            <Controller
                                                                name={"goal_complete"}
                                                                control={control}
                                                                rules={{
                                                                    required: !formData["goal_complete"] || !formData["goal_complete"][tagname] ? "This field is required" : false,
                                                                }}
                                                                render={({ field }) =>
                                                                    <Select
                                                                        {...field}
                                                                        options={tagsOptions}
                                                                        onChange={(e) => handleSelectChange("goal_complete", e, tagname)}
                                                                        value={formData["goal_complete"] && formData["goal_complete"][tagname] ? formData["goal_complete"][tagname] : false}
                                                                        classNamePrefix="wa-select"
                                                                        className="basic-select"
                                                                        menuPlacement={"auto"}
                                                                        isMulti
                                                                        styles={customStyles}
                                                                    />
                                                                }
                                                            />
                                                            {
                                                                errors["goal_complete"] && (
                                                                    <em className="error invalid-feedback d-flex">{errors["goal_complete"].message}</em>
                                                                )
                                                            }
                                                        </Col>
                                                        <Col lg={6}>
                                                            <label className="mt-2 mb-1" htmlFor={"goal_incomplete"}>On Goal Incomplete</label>
                                                            <Controller
                                                                name={"goal_incomplete"}
                                                                control={control}
                                                                rules={{
                                                                    required: !formData["goal_incomplete"] || !formData["goal_incomplete"][tagname] ? "This field is required" : false,
                                                                }}
                                                                render={({ field }) =>
                                                                    <Select
                                                                        {...field}
                                                                        options={tagsOptions}
                                                                        onChange={(e) => handleSelectChange("goal_incomplete", e, tagname)}
                                                                        value={formData["goal_incomplete"] && formData["goal_incomplete"][tagname] ? formData["goal_incomplete"][tagname] : false}
                                                                        classNamePrefix="wa-select"
                                                                        className="basic-select"
                                                                        menuPlacement={"auto"}
                                                                        isMulti
                                                                        styles={customStyles}
                                                                    />
                                                                }
                                                            />
                                                            {
                                                                errors["goal_incomplete"] && (
                                                                    <em className="error invalid-feedback d-flex">{errors["goal_incomplete"].message}</em>
                                                                )
                                                            }
                                                        </Col>
                                                    </Row>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="outbound-section row mt-5 me-5">
                                    <div className="group-header d-flex">
                                        <div className="outbound-stepnumber">{stepindex = stepindex + 1}</div>
                                        <span className="ms-4">{type === "automation" ? "Schedule Automation" : "Schedule Campaign"}</span>
                                    </div>
                                    <div className="ms-4">
                                        <Row>
                                            <Col md={10}>
                                                <Row className="mb-2 mt-2">
                                                    <Col md={10} className="align-content-end me-4">
                                                        <label htmlFor="Work_Hours">
                                                            {
                                                                type === "automation" ? (
                                                                    "Set the time zone, and working hours to schedule your automation."
                                                                ) : (
                                                                    "Set the launch date, time zone, and working hours to schedule your campaign."
                                                                )
                                                            }
                                                        </label>
                                                    </Col>
                                                    {false && <Col md={1}>
                                                        <Switch
                                                            onColor={"#034737"}
                                                            onChange={() => handleSwitchChange("isWorkHoursActive")}
                                                            checked={formData["isWorkHoursActive"]}
                                                            checkedIcon={<div className="on-switch-btn">On</div>}
                                                            uncheckedIcon={<div className="off-switch-btn">Off</div>}
                                                            width={60}
                                                            height={25}
                                                        />
                                                    </Col>}
                                                </Row>
                                                <div className={"work-hour-container"}>
                                                    <Row>
                                                        {
                                                            type === "campaign" &&
                                                            <Col md={6}>
                                                                <label className="mt-2 mb-1" htmlFor="launch_date">
                                                                    Launch Date
                                                                </label>
                                                                <DatePicker
                                                                    selected={formData["launch_date"] ? new Date(formData["launch_date"]) : null}
                                                                    className="form-control"
                                                                    onChange={(date) => {
                                                                        handleChangeDate(date); // Custom handler
                                                                    }}
                                                                    dateFormat="MMMM dd, yyyy"
                                                                    placeholderText="Choose date"
                                                                    popperPlacement="top"
                                                                    minDate={new Date()} // Disallow past dates
                                                                />
                                                                {error["launch_date"] && (
                                                                    <em className="error invalid-feedback d-flex">
                                                                        {error["launch_date"].message}
                                                                    </em>
                                                                )}
                                                            </Col>
                                                        }
                                                        <Col md={type === "campaign" ? 6 : 12}>
                                                            <label className="mt-2 mb-1" htmlFor="time_zone">Time Zone</label>
                                                            <Controller
                                                                name={"time_zone"}
                                                                control={control}
                                                                rules={{
                                                                    required: !formData["time_zone"] ? "This field is required" : false,
                                                                }}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        options={timeZones}
                                                                        onChange={(e) => handleSelectChange("time_zone", e)}
                                                                        value={formData["time_zone"]}
                                                                        classNamePrefix="wa-select"
                                                                        className="basic-select"
                                                                        menuPlacement={"auto"}
                                                                        styles={customStyles}
                                                                    />
                                                                )}
                                                            />
                                                            {
                                                                errors["time_zone"] && (
                                                                    <em className="error invalid-feedback d-flex">{errors["time_zone"].message}</em>
                                                                )
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <label className="mt-2 mb-1" htmlFor="start_time">Starting hours</label>
                                                            <Controller
                                                                name={"start_time"}
                                                                control={control}
                                                                rules={{
                                                                    required: !formData["start_time"] ? "This field is required" : false,
                                                                }}
                                                                render={({ field }) => (
                                                                    <input
                                                                        {...field}
                                                                        type="time"
                                                                        onChange={(e) => onInputChange(e)}
                                                                        value={formData["start_time"]}
                                                                        className="form-control custom-time-input"
                                                                        autoComplete="off"
                                                                    />
                                                                )}
                                                            />
                                                            {
                                                                errors["start_time"] && (
                                                                    <em className="error invalid-feedback d-flex">{errors["start_time"].message}</em>
                                                                )
                                                            }
                                                        </Col>
                                                        <Col md={6}>
                                                            <label className="mt-2 mb-1" htmlFor="end_time">Ending hours</label>
                                                            <Controller
                                                                name={"end_time"}
                                                                control={control}
                                                                rules={{
                                                                    required: !formData["end_time"] ? "This field is required" : false,
                                                                }}
                                                                render={({ field }) => (
                                                                    <input
                                                                        {...field}
                                                                        type="time"
                                                                        onChange={(e) => onInputChange(e)}
                                                                        value={formData["end_time"]}
                                                                        className="form-control custom-time-input"
                                                                        autoComplete="off"
                                                                    />
                                                                )}
                                                            />
                                                            {
                                                                errors["end_time"] && (
                                                                    <em className="error invalid-feedback d-flex">{errors["end_time"].message}</em>
                                                                )
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="outbound-section row mt-5 me-5">
                                    <div className="group-header d-flex">
                                        <div className="outbound-step-text">END</div>
                                    </div>
                                </div>
                            </div>
                            <button className="hide " type={"submit"} ref={subBtnRef}>save</button>
                        </Form>
                    </div>
                </Container>
            </Fragment>
        )
    }

    let title = "";
    if (formId) {
        if (type === "automation") {
            title = "Automations";
        } else {
            title = "Update Campaign";
        }
    } else {
        if (type === "automation") {
            title = "New Automation";
        } else {
            title = "Create Campaign";
        }
    }

    return (
        <Fragment>
            <Common
                title={title}
                titleContent={
                    <span>{title}</span>
                }
                titleBody={
                    !isMobile() &&
                    getButtonsContainer()
                }
                mainLoader={mainLoader}
                mainContent={
                    <div className="ps-3 pe-3 outbound-config-container w-100">
                        {
                            isMobile() &&
                            getButtonsContainer()
                        }
                        {
                            getMainContainer()
                        }
                    </div>
                }
            />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OutboundOperations);
