import React, { createContext, useContext, useEffect, useState } from 'react';

export const PopupContext = createContext();

export const CallProvider = ({ children }) => {
    const [isPopoverOpen, setPopoverOpen] = useState(false);
    const [agentname, setAgentname] = useState("");
    const [curDuaration, setCurDuaration] = useState("0:00");
    const [isCallReady, setIsCallReady] = useState(false);
    const [toggleFun,setToggleFun] = useState(() => () => {});
    const [stopCall,setStopCall] = useState(() => () => {});

    return (
        <PopupContext.Provider value={{
            isPopoverOpen,
            setPopoverOpen,
            agentname,
            setAgentname,
            curDuaration,
            setCurDuaration,
            isCallReady,
            setIsCallReady,
            toggleFun,
            setToggleFun,
            stopCall,
            setStopCall,
        }}>
            {children}
        </PopupContext.Provider>
    );
};

export const useWebCallContext = () => useContext(PopupContext);
