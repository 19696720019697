import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import { HashRouter } from "react-router-dom";
import "./assets/base.scss";
//import './assets/css/base.css';
import './assets/css/index.css';
import './assets/css/flaticon/flaticon-br.css';
import './assets/css/flaticon/flaticon-bs.css';
import './assets/css/flaticon/flaticon-rc.css';
import './assets/css/flaticon/flaticon-rr.css';
import './assets/css/flaticon/flaticon-rs.css';
import './assets/css/flaticon/flaticon-sc.css';
import './assets/css/flaticon/flaticon-sr.css';
import './assets/css/flaticon/flaticon-ss.css';
import './assets/css/flaticon/flaticon-tc.css';
import Main from "./Main";
import configureStore from "./config/configureStore";
import { Provider } from "react-redux";
import { ThemeProvider } from "./Context/ThemeContext";
import { CallProvider } from "./Common/WebCallContext";
import WebCallPopOver from "./WebCallPopOver";
import Agents from "./Components/Agents";
import GoogleTranslateWidget from "./Components/Common/GoogleTranslateWidget";
import { LanguageProvider } from "./Context/LanguageContext";

const store = configureStore();
const rootElement = document.getElementById("root");

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <ThemeProvider>
          <LanguageProvider>
            <GoogleTranslateWidget />
            <CallProvider>
              <Component />
              <WebCallPopOver/>
            </CallProvider>
          </LanguageProvider>
        </ThemeProvider>
      </HashRouter>
    </Provider>,
    rootElement
  );
};

renderApp(Main);

serviceWorker.unregister();
