import React, { Fragment } from 'react';
import { Controller, useForm } from "react-hook-form";
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Row,
    Col,
    Button
} from "reactstrap";

const OpenImportModal = (props) => {
    const { isImportModalOpen, closeModal, onSubmit } = props;

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handleFormSubmit = (data) => {
        onSubmit(data,"twilio");
        closeModal();
    };

    return (
        <>
            <Modal isOpen={isImportModalOpen} className='w-75'>
                <ModalHeader toggle={closeModal}>
                    Import Twilio Phone Number
                </ModalHeader>
                <ModalBody>
                    <Fragment>
                        <div className="field-details">
                            {/* Phone Number */}
                            <Row className="mt-2">
                                <Col md={12}>
                                    <label className="form-label" htmlFor="phone_number">
                                        Twilio Phone Number
                                    </label>
                                    <Controller
                                        name="phone_number"
                                        control={control}
                                        rules={{ required: "Phone number is required." }}
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                id="phone_number"
                                                className={`form-control ${errors.phone_number ? "is-invalid" : ""}`}
                                            />
                                        )}
                                    />
                                    {errors.phone_number && (
                                        <div className="invalid-feedback">
                                            {errors.phone_number.message}
                                        </div>
                                    )}
                                </Col>
                            </Row>

                            {/* Account SID */}
                            <Row className="mt-2">
                                <Col md={12}>
                                    <label className="form-label" htmlFor="account_sid">
                                        Twilio Account SID
                                    </label>
                                    <Controller
                                        name="account_sid"
                                        control={control}
                                        rules={{ required: "Account SID is required." }}
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                id="account_sid"
                                                className={`form-control ${errors.account_sid ? "is-invalid" : ""}`}
                                            />
                                        )}
                                    />
                                    {errors.account_sid && (
                                        <div className="invalid-feedback">
                                            {errors.account_sid.message}
                                        </div>
                                    )}
                                </Col>
                            </Row>

                            {/* Auth Token */}
                            <Row className="mt-2">
                                <Col md={12}>
                                    <label className="form-label" htmlFor="auth_token">
                                        Twilio Auth Token
                                    </label>
                                    <Controller
                                        name="auth_token"
                                        control={control}
                                        rules={{ required: "Auth Token is required." }}
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                id="auth_token"
                                                className={`form-control ${errors.auth_token ? "is-invalid" : ""}`}
                                            />
                                        )}
                                    />
                                    {errors.auth_token && (
                                        <div className="invalid-feedback">
                                            {errors.auth_token.message}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </Fragment>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="container-footer-save theme-bg-g btn-hover-shine"
                        onClick={handleSubmit(handleFormSubmit)}
                    >
                        Import from Twilio
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default OpenImportModal;
