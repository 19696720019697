import React, { useEffect, Fragment, useRef, useState } from "react";
import cx from "classnames";
import { Input } from "reactstrap";

const CratioCheckBox = (props) => {
  const [value, setValue] = useState(props.defaultValue == "Yes" ? true : false );
  useEffect(() => {
    (async () => {})();
  });

  const onChange = async (e) => {
    //if (e.target.validity.valid) {
      let name = e.target.name;
      let value = e.target.checked ? "Yes" : "No";
      if (props.onChange) {
        props.onChange(e, { name: name, value: value }, props.attributes);
      }
      setValue(e.target.checked);
    //}
  };
  return (
    <div
      className={cx(props.customClass)}
      {...props.attributes}
      data-value={value}
    >
      <Input
          className={`form-control custom-contact-checkbox ${props.inputClass} p-0`}
        style={{ backgroundPosition: '0' }}
        type="checkbox"
        checked={value}
        onChange={(e) => onChange(e)}
        {...props.controls}
      />
    </div>
  );
};

export default CratioCheckBox;
