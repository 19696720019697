import React, { useState, useEffect, Fragment } from "react";
import Select from "react-select";
import DateFilter from "../../Common/DateFilter";
import { Button, Input, Col } from "reactstrap";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { connect, connectAdvanced } from "react-redux";
import Loading from "../../Common/Loading";
import {
  populateFormDetails,
  getReferenceList,
  showToast,
  isMobile,
} from "../../Common/Appcommon";
import { subDays } from "date-fns";
import Switch from "react-switch";  

const Filter = (props) => {
  const { CallTypeOption = [],setDemo } = props;
  const startDate = subDays(new Date(), 6);
  const endDate = new Date();
  const [dateFilter, setDateFilter] = useState({
    Start_DateTime: [startDate, endDate],
    noofdays: 6,
  });
  const [defAgntDrpDwnLst, setDefAgntDrpDwnLst] = useState({});
  const [defCallType, setDefCallType] = useState(CallTypeOption[0]);
  const [agentDropDownList, setAgentDropDownList] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [compareAgent1, setCompareAgent1] = useState("");
  const [compareAgent2, setCompareAgent2] = useState("");
  const [compareAgentOption, setCompareAgentOption] = useState({});
  const [checked, setChecked] = useState(false);
  const [callogvalue1, setCallLogValue1] = useState([]);
  const [callogvalue2, setCallLogValue2] = useState([]);
  const [callogFilter1, setCallLogFilter1] = useState('');
  const [callogFilter2, setCallLogFilter2] = useState('');
  const [searchStr, setSearchStr] = useState("");
  let mobile = props.neeedbottomfilter ?? isMobile();

  const {
    needCallFilter,
    needDateFilter,
    needAgentFilter,
    getdate,
    getAgentvalue,
    getcallType,
    callogFilters,
    needAgentCompareFilter = false,
    getCompareAgentvalue,
    callLogOption1,
    callLogOption2,
    needDemo,
    getDemo,
    isCSVDownload,
    isAllowWaFilter,
  } = props;
 
  // Fetch agent names
  const fetchAgentDetails = async () => {
    setShowLoading(true);
    let formDetail = await populateFormDetails("table_37");
    let fDetail = formDetail[1];
    let refDetail = fDetail.filter(
      (record) => record[2] === "table_250_0_table_250_id"
    )[0];
    if (refDetail && refDetail[35] !== undefined) {
      let res = await getReferenceList(refDetail[35]);
      if (res.issuccess) {
        let optionList = res.data;
        let dropDown = optionList[0];
        let allAgentsOption = { value: "all_agent", label: "All Agents" };
        dropDown.unshift(allAgentsOption);
        setDefAgntDrpDwnLst(allAgentsOption);
        setAgentDropDownList(dropDown);
        if (needAgentCompareFilter) {
          dropDown.shift();
          let limitdropDownlist = dropDown; //.slice(0, 4)
          limitdropDownlist.map((data, index) => {
            if (data.value === "1") {
              setCompareAgent1(dropDown[index]);
            } else if (data.value === "2") {
              setCompareAgent2(dropDown[index]);
            }
          });
          setCompareAgentOption(limitdropDownlist);
        }
      }
    }
    setShowLoading(false);
  };

  // Handle date changes
  const handleDateChange = (startDate, endDate) => {
    if (startDate && endDate) {
      let dateobj = {};
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      const timeDifference = endDate.getTime() - startDate.getTime();
      const numberOfDays = Math.floor(timeDifference / (1000 * 3600 * 24)) + 1;
      dateobj["Start_DateTime"] = [startDate, endDate];
      dateobj["noofdays"] = numberOfDays;
      setDateFilter(dateobj);
      if (getdate && !needAgentCompareFilter) {
        getdate(dateobj);
      }
    }
  };

  // Handle agent selection
  const onAgentSelectionChange = (selectedOption) => {
    setDefAgntDrpDwnLst(selectedOption);
    if (getAgentvalue) {
      getAgentvalue(selectedOption);
    }
  };

  // Handle call type selection
  const onCallTypeSelectionChange = (selectedOption) => {
    setDefCallType(selectedOption);
    if (getcallType) {
      getcallType(selectedOption);
    }
  };

  const exportCSV = (e) => {
    props.onClickExports(e);
  };

  const handleSearch = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, "");
    setSearchStr(numericValue);
  };

  const handleCallogFilters1 = (value) => {
    if (value !== undefined && value !== null) {
      let lable = value.label ? value.label : '';
      setCallLogValue1(value);
      setCallLogFilter1(lable);
    } else{
      setCallLogValue1(value);
      setCallLogFilter1(value);
    }   
  };
  const handleCallogFilters2 = (value) => {
    if (value !== undefined && value !== null) {
      if (value.label === "Connected") {
        setCallLogFilter2("connected_calls");
      } else if (value.label === "Not Connected") {
        setCallLogFilter2("not_connected_calls");
      } else if (value.label === "Goal Complete") {
        setCallLogFilter2("goals_achieved");
      } else if (value.label === "Goal Incomplete") {
        setCallLogFilter2("goals_not_achieved");
      }
      setCallLogValue2(value);
    } else {
      setCallLogValue2(value);
      setCallLogFilter2(value);
    }
  };

  const searchCallLogs = () => {
    if ((callogFilter1 !== "" && callogFilter1 !== null) || (callogFilter2 !== "" && callogFilter2 !== null) || (dateFilter !== "" && dateFilter !== null)) {
      let autocampaign = callogFilter1;
      let statusfilter = callogFilter2;
      let cond = {
          status: statusfilter,
          outbound: autocampaign,
          date: dateFilter,
      };
      props.getCallLogs(searchStr, cond);
    }
    };

  // Handle Compare Agent Selection
  const handleCompareAgentChange = (event, agentNumber) => {
    if (
      (agentNumber === 1 && event.value === compareAgent2?.value) ||
      (agentNumber === 2 && event.value === compareAgent1?.value)
    ) {
      showToast(
        "Selecting the same agent for comparison is not allowed.",
        "top-right",
        "error"
      );
      return;
    }
    if (agentNumber === 1) {
      setCompareAgent1(event);
    } else if (agentNumber === 2) {
      setCompareAgent2(event);
    }
  };

  
 

  const handleSearchClick = () => {
    let value1 = compareAgent1.value;
    let value2 = compareAgent2.value;
    if (getCompareAgentvalue) {
      getCompareAgentvalue([value1, value2], dateFilter);
    }
  };

  const switchonchange = (checked) => {
    setChecked(checked);
    if (getDemo) {
      getDemo(checked);
    }
  };


  // Fetch agent names once on mount
  useEffect(() => {
    fetchAgentDetails();
  }, []);

  //change switch state
  useEffect(()=>{
    switchonchange(setDemo)
  },[setDemo]);

  return (
    <Loading
      showLoader={showLoading}
      mainContent={
        <Fragment>
          <div className={`${mobile ? "mobile" : "web"}`}>
            <div className={`filter-container custome-filter`}>
            <div className={`${!mobile || props.guiValidation === 'calllog_web' ? "d-flex justify-content-end" : ""}`}>                {needDemo && (
                  <>
                    <span className={`d-flex align-items-center ${mobile && "justify-content-end"}`}>
                      <div className="me-1 demon-header mb-1">Demo</div>
                      <div className="me-2 mt-1">
                        <Switch
                          height={20}
                          width={40}
                          onColor={"#034737"}
                          className=""
                          onChange={switchonchange}
                          checked={checked}
                        />
                      </div>
                    </span>
                  </>
                )}
                {/* Call Type Dropdown */}
                {needCallFilter && (
                  <div className="me-2 filter-width">
                    <Select
                      name="selected_form"
                      id="selected_form"
                      className="basic-select"
                      classNamePrefix="sub-select wa-select"
                      value={defCallType}
                      onChange={onCallTypeSelectionChange}
                      options={CallTypeOption}
                    />
                  </div>
                )}

                {/* Agent Dropdown */}
                {needAgentFilter && (
                  <div className="me-2 filter-width">
                    <Select
                      name="selected_form"
                      id="selected_form"
                      className="basic-select"
                      classNamePrefix="sub-select wa-select"
                      value={defAgntDrpDwnLst}
                      onChange={onAgentSelectionChange}
                      options={agentDropDownList}
                    />
                  </div>
                )}
                {needAgentCompareFilter && (
                  <>
                    <div className="me-2 filter-width">
                      <Select
                        name="selected_form"
                        id="selected_form"
                        className="basic-select"
                        classNamePrefix="sub-select wa-select"
                        value={compareAgent1}
                        onChange={(e) => handleCompareAgentChange(e, 1)}
                        options={compareAgentOption}
                      />
                    </div>

                    <div className="me-2 filter-width">
                      <Select
                        name="selected_form"
                        id="selected_form"
                        className="basic-select"
                        classNamePrefix="sub-select wa-select"
                        value={compareAgent2}
                        onChange={(e) => handleCompareAgentChange(e, 2)}
                        options={compareAgentOption}
                      />
                    </div>
                  </>
                )} 
                {callogFilters && (
                  <>
                    <div className={props.guiValidation === 'calllog_web' ? "me-1 filter-callog-width" : ""}>                      
                      <div className="input-with-icon">
                        <span className="search-icon">
                          <i className="icon-color fi fi-rs-search"></i>
                        </span>
                        <Input
                          id="userName"
                          className="search-input"
                          autoComplete="off"
                          placeholder="Search by Phone.No"
                          onChange={(e) => {
                            handleSearch(e);
                          }}
                          value={searchStr}
                        />
                      </div>
                    </div>
                    {
                      isAllowWaFilter && 
                      <div className={props.guiValidation === 'calllog_web' ? "me-1 filter-callog-width" : ""}>
                        <Select
                            placeholder="All"
                            name="selected_form"
                            id="selected_form"
                            className="basic-select"
                            classNamePrefix="sub-select wa-select"
                            value={callogvalue1}
                            onChange={(value) => handleCallogFilters1(value)}
                            options={callLogOption1.map(({ value, lable }) => ({
                            value,
                            label: lable,
                            }))}
                            isClearable={true}
                        />
                      </div>
                    }
                    <div className={props.guiValidation === 'calllog_web' ? "me-1 filter-callog-width" : ""}>
                      <Select
                        placeholder="All"
                        name="selected_form"
                        id="selected_form"
                        className="basic-select"
                        classNamePrefix="sub-select wa-select"
                        value={callogvalue2}
                        onChange={(e) => handleCallogFilters2(e)}
                        options={callLogOption2.map(({ value, lable }) => ({
                          value,
                          label: lable,
                        }))}
                        isClearable={true}
                      />
                    </div>
                    <div className={props.guiValidation === 'calllog_web' ? "me-0 filter-callog-width" : ""}>
                    <div className={`d-flex justify-content-center ${props.guiValidation === 'calllog_web' ? "" : "p-1"}`}>
                    <div className="me-2 mt-n1 justify-content-center">
                    <DateFilter
                        handleDateChange={handleDateChange}
                        defaultSelectIndex={2}
                    />
                    </div> 
                    <div className="mt-n1 justify-content-center">               
                    <Button
                        className="btn btn-secondary theme-bg-g btn-hover-shine"
                        style={{height:'37px'}}
                        onClick={searchCallLogs}
                      >
                        <i className="fi fi-br-search me-1"></i>Search
                      </Button>
                      </div>
                      </div>
                    {isCSVDownload && 
                      <div className="me-2">
                        <Button
                            className="btn btn-secondary theme-bg-g btn-hover-shine"
                            onClick={e => exportCSV(e)}
                        >
                            <i className="fi fi-br-search me-2"></i>Downloaded as CSV
                        </Button>
                      </div>
                    }
                    </div>
                  </>
                )}
                <div className="d-flex justify-content-end">
                  {/* Date Filter */}
                  {needDateFilter && (
                    <div className="me-2">
                      <DateFilter
                        handleDateChange={handleDateChange}
                        defaultSelectIndex={3}
                        onfirstnotneed={true}
                      />
                    </div>
                  )}
                  {needAgentCompareFilter && (
                    <div className="me-2">
                      <Button
                        className="btn btn-secondary theme-bg-g btn-hover-shine"
                        style={{height:'37px'}}
                        onClick={handleSearchClick}
                      >
                        <i className="fi fi-br-search me-2"></i>Generate
                      </Button>
                    </div>
                  )}
                  </div>
              </div>
            </div>
          </div>
        </Fragment>
      }
    />
  );
};
const mapStateToProps = (state) => ({
  appCustomTitle: state.ThemeOptions.appCustomTitle,
});

const mapDispatchToProps = (dispatch) => ({
  setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
